import {Amplify,Auth, API,Cache} from 'aws-amplify';
import { Link, withAuthenticator } from '@aws-amplify/ui-react';
import awsconfig from './../aws-exports';
import { useState,useEffect } from 'react';

import Logo from '../ui-components/Logo';
import { Flex, Text, Button, Image, Input, CheckboxField, Label } from '@aws-amplify/ui-react';
import { createUser } from '../graphql/mutations';
import { getUser } from '../graphql/queries';
import  { useNavigate } from 'react-router'

Amplify.configure(awsconfig);


function Register({ signOut }) {

  useEffect(() => {
    checkAccount();
    
  },[]);

  async function checkAccount(){
    let cognitoUser = await Auth.currentAuthenticatedUser({
      bypassCache: false
    });
    const { attributes } = cognitoUser;
    const credentials = await Auth.currentUserCredentials();
console.log("attributes",attributes);
    const result = await API.graphql({ query: getUser , variables:{id:attributes.email}});
    if(result['data'].getUser){
      const name  = result['data'].getUser.lastName;
      Cache.setItem('user', result['data'].getUser);
      // user exists so forward to products page
      if(name && name.length > 1){
        navigate("/products");
      }
    }
  }

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [agreed, setAgreed] = useState(false);

  const [hasFirstNameError, setHasFirstNameError] = useState(false);
  const [hasLastNameError, setHasLastNameError] = useState(false);
  const [hasAgreeError, setHasAgreeError] = useState(false);
  const navigate = useNavigate();

  function updateFirstName(e) {
    setFirstName(e.target.value );
  }

  function updateLastName(e) {
    setLastName(e.target.value );
  }

  async function create() {
    
    if(firstName == ""){
      setHasFirstNameError(true);
      return;
    }
    else{
      setHasFirstNameError(false);
    }

    if(lastName == ""){
      setHasLastNameError(true);
      return;
    }
    else{
      setHasLastNameError(false);
    }
    
    if(!agreed){
      setHasAgreeError(true);
      return;
    }
    else{
      setHasAgreeError(false);
    }

    let cognitoUser = await Auth.currentAuthenticatedUser({
      bypassCache: false
    });
    const { attributes } = cognitoUser;
    const credentials = await Auth.currentUserCredentials();

    
    const createUserPayload = {
      email:attributes.email,
      username:attributes.email,
      identityID:credentials.identityId,
      firstName:firstName,
      lastName:lastName,
      hasRfToken:false,
      id:attributes.email
    }

    await API.graphql({query: createUser,variables:{input:createUserPayload}});
    
    navigate("/products");

  }
   
  return (
    <Flex
      gap="16px"
      direction="column"
      width= "100%"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="86px 60px 16px 60px"
      
    >
    <Flex
      gap="16px"
      direction="column"
      width= "60%"
      height= "60%"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="16px 60px 16px 60px"
      
    >
      <Logo
        display="flex"
        gap="10px"
        direction="column"
        width="128px"
        height="126px"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        color="brand"
       
      ></Logo>
      <Text
        fontFamily="Inter"
        fontSize="32px"
        fontWeight="500"
        color="rgba(13,26,38,1)"
        lineHeight="40px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Sign Up"
        
      ></Text>
      <Label htmlFor="firstName">First Name</Label>
      <Input
      id="firstName"
        width="unset"
        height="unset"
        label="First Name"
        placeholder=" "
        shrink="0"
        alignSelf="stretch"
        size="default"
        isDisabled={false}
        labelHidden={false}
        variation="default"
        hideShowPassword={true}
        onChange={updateFirstName}
        hasError={hasFirstNameError}
      ></Input>
      <Label htmlFor="lastName">Last Name</Label>
      <Input
      id="lastName"
        width="unset"
        height="unset"
        label="Last Name"
        placeholder=" "
        shrink="0"
        alignSelf="stretch"
        size="default"
        isDisabled={false}
        labelHidden={false}
        variation="default"
        hideShowPassword={true}
        onChange={updateLastName}
        hasError={hasLastNameError}
      ></Input>
      
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
       
      >
        <CheckboxField
          width="unset"
          height="unset"
          label="I agree to the Terms of the Use and Privacy Policy"
          shrink="0"
          size="default"
          defaultChecked={false}
          isDisabled={false}
          labelPosition="end"
          checked={agreed}
          onChange={(e) => setAgreed(e.target.checked)}
          hasError={hasAgreeError}
          value="yes"
        ></CheckboxField>
        <Link href='https://gaiiff.com/privacy/'>Privacy Policy</Link>
      </Flex>
      <Button
        width="unset"
        height="unset"
        shrink="0"
        alignSelf="stretch"
        size="default"
        isDisabled={false}
        variation="primary"
        children="Sign Up"
        onClick={create}
      ></Button>
      <Flex
        gap="8px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        
      >
        </Flex>
      </Flex>
    </Flex>
  );
}

export default withAuthenticator(Register);
