import {Amplify, API, Auth, Cache} from 'aws-amplify';
import { withAuthenticator,Flex,Text } from '@aws-amplify/ui-react';
import React,{useEffect, useState} from 'react';
import awsconfig from './../aws-exports';
import AccountRegionCard from '../ui-components/AccountRegionCard';
import {Hero} from "../ui-components";
import { getUser, getStripeCustomer } from '../graphql/queries';
import { updateUser } from '../graphql/mutations';
import  { useNavigate } from 'react-router'
import GeneralFooter from '../ui-components/GeneralFooter';
Amplify.configure(awsconfig);

const pricingTableId = process.env.REACT_APP_PRICING_TABLE_ID;
const publishableKey = process.env.REACT_APP_PUBLISHABLE_KEY;
console.log('All environment variables:', process.env);
console.log(process.env.NODE_ENV);
console.log(publishableKey, publishableKey);

function Products({ signOut, user }) {

  const navigate = useNavigate();

  function StripePriceTable(){
    
    const[isLoading, setIsLoading] = useState(true);
    const[identityID, setIdentityID] = useState("");
    const[customerEmail, setCustomerEmail] = useState("");

    async function checkAccountPricing(){
      try{
        setIsLoading(true)
        let cognitoUser = await Auth.currentAuthenticatedUser({
          bypassCache: false
        });
        
        const { attributes } = cognitoUser;
        const credentials = await Auth.currentUserCredentials();
        const identityArray = credentials.identityId.split(":");
        let identityId = identityArray[1];  
        
        setCustomerEmail(cognitoUser.attributes.email)  
        setIdentityID(identityId);
        const search = window.location.search;
        const params = new URLSearchParams(search);

        const checkout = params.get('checkout');
        
        if(checkout && checkout.length > 0){
          setIsLoading(true);  
        }
        else {
          setIsLoading(false); 
        }
      }catch(error){
        console.log(error)
      }
    }
    useEffect(() => {
      checkAccountPricing();
      const script = document.createElement("script");
      script.src = "https://js.stripe.com/v3/pricing-table.js";
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }, []);
  
    if(isLoading){
      return null;
    }
    return (
      React.createElement("stripe-pricing-table", {
        "pricing-table-id": pricingTableId,
        "publishable-key": publishableKey,
        "client-reference-id":identityID,
        "customer-email":customerEmail
      })
        
    );
  }  
    
  useEffect(() => {
    checkAccount();
    
  }, []); 
    
  

  async function checkAccount(){
    try{
      let cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: false
      });
      const { attributes } = cognitoUser;

      // If redirecting back from Stripe
      const search = window.location.search;
      const params = new URLSearchParams(search);
      console.log(params)
      const checkout = params.get('checkout');
      console.log(checkout)
      if(checkout && checkout.length > 0){
        const response = await API.graphql({
          query: getStripeCustomer
          ,variables : {"checkout": checkout}
        });
        let created = 0;
        let customerId = "";
        let plan = "";
        const stripeData = JSON.parse(response["data"]["getStripeCustomer"]);
        
        // get the newest subscription of this customer checkout session as it's the one they just completed
        console.log(stripeData);
        for(let i = 0; i < stripeData['body']['data'].length; i++){
          if(stripeData['body']['data'][i]['created'] > created){
            created = stripeData['body']['data'][i]['created'];
            customerId = stripeData['body']['data'][i]['customer']
            plan = stripeData['body']['data'][i]['plan']['product']
          }
        }
        console.log("customerId, updated user",customerId);
        console.log("id, updated user",cognitoUser.attributes.email);

        await API.graphql({ query: updateUser , variables:{input:{id:cognitoUser.attributes.email, stripeId:customerId }}});
        const result = await API.graphql({ query: getUser , variables:{id:cognitoUser.attributes.email}});
        if(result['data'].getUser){
          result['data'].getUser['plan'] = plan;
          Cache.setItem('user', result['data'].getUser);
        }
        console.log('db is ', result['data'].getUser["refreshToken"])
        if(result['data'].getUser["refreshToken"] == null){
          navigate("/authorize");
        }
        else {
          navigate("/sales-report");
        }
        
      }
    }catch(error){
      console.error(error);

    }
    
    // const result = await API.graphql({ query: getUser , variables:{id:cognitoUser.attributes.email}});
    // if(result['data'].getUser == null || )
    // const stripeId  = result['data'].getUser.stripeId;
    // //user exists so forward to products page
    // if(stripeId && stripeId.length > 1){
    //   navigate("/authorize");
    // }

  }


  return (
    <div style={{width:"100%"}} >
    <Flex
      gap="24px"
      direction="column"
      width="100%"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="48px 48px 0px 48px"
      backgroundColor="rgba(250,250,250,1)"
      
    >
      <Flex
        gap="12px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        
      >
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="600"
          color="rgba(0,96,122,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Pricing"
          
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="32px"
          fontWeight="600"
          color="rgba(13,26,38,1)"
          lineHeight="32px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Choose your plan"
          
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          color="rgba(92,102,112,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="7 day free trial for free plan, no credit card needed"
          
        ></Text>
        
      </Flex>
     
      <p/>
      
      
      </Flex>
      
      <StripePriceTable/>
      
      <GeneralFooter/>
      
      </div>
      
  );
}

export default withAuthenticator(Products);
