import React from 'react'
import { useState,useEffect,useRef } from 'react';
import { Amplify, Auth, API, Storage,Cache } from 'aws-amplify';
import {Flex, View, Button,Text,
    Divider,Radio,SelectField,TextField,Icon, Link} from '@aws-amplify/ui-react';
    import  NavBar  from '../components/navbar.tsx';
import studioTheme from '../ui-components/studioTheme';
import SideBar from '../ui-components/SideBar';
import AccountAccountInformation from '../ui-components/Forecasts'; 
import { withAuthenticator, Loader } from '@aws-amplify/ui-react';
import awsconfig from '../aws-exports';
import { getUser, getStripeCustomer } from '../graphql/queries';
import { createImportSpecifier } from 'typescript';
import Selectedbutton from '../ui-components/SelectedButton';
import ItemCard from '../ui-components/ItemCard';
import ItemCardNew from '../components/item-card.tsx';
import Partners from '../ui-components/Partners';
import moment from 'moment';
import SalesReportOptions from '../ui-components/SalesReportOptions';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, ChartDataset,LabelItem, Color, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, scales} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

Amplify.configure(awsconfig);

function SalesReport({changeView}) {
    
    const [isLoading, setIsLoading] = useState(true);
    const [lineChartOptions, setLineChartOptions] = useState({});
    const [asinIsLoading, setasinIsLoading] = useState(true);
    const [selectValue, setSelectValue] = useState("lastThirtyDays");
    const [orderedProducts, setOrderedProducts] = useState({});
    const [asinOrderedProducts, setAsinOrderedProducts] = useState({});
    const [chartData,setChartData]= useState({});
  
    const [averages1,setAverages1]= useState("");
    const [averages2,setAverages2]= useState("");
    const [averages3,setAverages3]= useState("");

    const [subscriptionPlan,setSubscriptionPlan]= useState(null);
    const [subscriptionLoading, setSubscriptionLoading] = useState(true);
    
    const _orderedProductSales= useRef<Array<number>>([]);
    const _orderedProductSalesB2B = useRef<Array<number>>([]);
    const _orderedProductSalesNotB2B = useRef<Array<number>>([]);

    const _averageSalesPerOrderItem = useRef<Number[]>([]);
    const _averageSalesPerOrderItemB2B = useRef<Number[]>([]);
    const _averageSellingPrice = useRef<Number[]>([]);
    const _averageSellingPriceB2B = useRef<Number[]>([]);
    const _averageUnitsPerOrderItem = useRef<Number[]>([]);
    const _averageUnitsPerOrderItemB2B = useRef<Number[]>([]);
    const _claimsAmount = useRef<Number[]>([]);
    const _claimsGranted = useRef<Number[]>([]);
    
    const _ordersShipped = useRef<Number[]>([]);
    const _refundRate = useRef<Number[]>([]);
    const _shippedProductSales = useRef<Number[]>([]);
    const _totalOrderItems = useRef<Number[]>([]);
    const _totalOrderItemsB2B = useRef<Number[]>([]);
    const _totalOrderItemsNotB2B = useRef<Number[]>([]);
    const _unitsOrdered = useRef<Number[]>([]);
    const _unitsOrderedB2B= useRef<Number[]>([]);
    const _unitsOrderedNotB2B= useRef<Number[]>([]);
    const _unitsRefunded= useRef<Number[]>([]);
    const _unitsShipped = useRef<Number[]>([]);
    //TrafficByDate Points
    const _averageOfferCount = useRef<Number[]>([]);
    const _averageParentItems = useRef<Number[]>([]);
    const _browserPageViews = useRef<Number[]>([]);
    const _browserSessions = useRef<Number[]>([]);
    const _buyBoxPercentage = useRef<Number[]>([]);
    const _feedbackReceived = useRef<Number[]>([]);
    const _mobileAppPageViews = useRef<Number[]>([]);
    const _mobileAppSessions = useRef<Number[]>([]);
    const _negativeFeedbackReceived = useRef<Number[]>([]);
    const _orderItemSessionPercentage = useRef<Number[]>([]);
    const _orderItemSessionPercentageB2B = useRef<Number[]>([]);
    const _orderItemSessionPercentageNotB2B = useRef<Number[]>([]);
    const _pageViews = useRef<Number[]>([]);
    const _receivedNegativeFeedbackRate = useRef<Number[]>([]);
    const _sessions = useRef<Number[]>([]);
    
    const _salesPerBrowserSession = useRef<Number[]>([]);
  
    const _salesPerMobileAppPageViews = useRef<Number[]>([]);
    const _sessionPercentage = useRef<Number[]>([]);
    const _pageViewPercentage = useRef<Number[]>([]);
    
    const _strDates = useRef<String[]>([]);
    const _filterBy = useRef<String>("");
    const _sessionType = useRef<String>("");

    const _unitSessionPercentage = useRef<Number[]>([]);
    const _unitSessionPercentageNotB2B = useRef<Number[]>([]);

    const [currency, setCurrency] = useState("$");
   

    //data variables
    const _retailMobileAppSession = useRef<Number[]>([]);
    const _corporateMobileAppSession = useRef<Number[]>([]);
    const _retailBrowserSession = useRef<Number[]>([]);
    const _corporateBrowserSession = useRef<Number[]>([]);
    const _retailSession = useRef<Number[]>([]);
    const _corporateSession = useRef<Number[]>([]);

    const _salesInTotal = useRef<Number[]>([]);
    const _salesInAmazonBusiness = useRef<Number[]>([]);
    const _retailSales = useRef<Number[]>([]);
    const _retailFeaturedOfferBuyBoxPercentage = useRef<Number[]>([]);
    const _businessFeaturedOfferBuyBoxPercentage = useRef<Number[]>([]);
    

    let lineChartData: Number[] = [];
    let lineChartLabels: String[] = [];
    
    let asinLineChartOptions: any = {};
    let asinLineChartLabels: String[] = [];

    let salesByDate = {};
    let trafficByDate = {};
    let asinParentList = {};
    let asinChildList = {};

    let asinSales = [];
    let asinStrDates  = [];

    let view = "sales";
    
    useEffect(() => {
        ChartJS.register(CategoryScale,LinearScale, PointElement, LineElement,zoomPlugin, Tooltip);
        downloadTraffic();
        fetchSubscription();
    },[]);// <-- Have to pass in [] here or is called on every render
    
    function moveAsinView(e) {
      if(e.target.value == "asin"){
        changeView('asin');
      }
    }

    async function fetchSubscription(){
      const user = Cache.getItem('user');

      let refreshToken = {}
      try{
          var response;
          if(user){
              response = await API.graphql({
                  query: getStripeCustomer
                  ,variables : {"email": user.id, "stripeCustomerId": user.stripeId}
              })
  
              const stripeData = JSON.parse(response["data"]["getStripeCustomer"]);
              
              if(stripeData["body"]["data"][0] && stripeData["body"]["data"][0]["product_name"]["name"]){
                  setSubscriptionPlan(stripeData["body"]["data"][0]["product_name"]["name"]);
              }
          }
      }catch(exception){
        console.error('Error fetching subscription:', exception);
      } finally {
        setSubscriptionLoading(false);
      }
    }

    async function downloadTraffic() {
    
      try{
        const market = Cache.getItem('marketCode');
        let url = market+ "_SalesAndTrafficReport/TrafficByDate.json";
        
        const result = await Storage.vault.get(url, { download: true });
        trafficByDate = await new Response(result.Body).json();
        
        //whichever is faster
        let urlSales = market+ "_SalesAndTrafficReport/SalesByDate.json";
    
        const resultSales = await Storage.vault.get(urlSales, { download: true });
        salesByDate = await new Response(resultSales.Body).json();
      }
      catch {
        trafficByDate = {};
        salesByDate = {};
      }
      await sortSTR();
        
    }

    function findAverageCurrentData(array){
        var total = 0;
        var denominator = 0;
        
        for(var i = 0; i < array.length; i++) {
            total += Number(array[i]);
            
            if(Number(array[i]) != 0){
              denominator++;
            } 
        }

        let returnValue = (total / denominator).toFixed(2);
        if(returnValue == "NaN"){
          return 0;
        }
        
        return returnValue;
    }

    async function sortSTR(){
        
      let temp;
      let unitSessionbtob;
      //console.log(salesByDate[0].date.replace(regex, '/'))
      //console.log(salesByDate)
      
      for(let i in salesByDate){
          _averageSellingPrice.current.push(salesByDate[i].averageSellingPrice)    
          //averageSellingPriceB2B.push(salesByDate[i].averageSellingPriceB2B.amount)   
          _averageUnitsPerOrderItem.current.push(salesByDate[i].averageUnitsPerOrderItem) 
          _averageUnitsPerOrderItemB2B.current.push(salesByDate[i].averageUnitsPerOrderItemB2B) 
          _claimsAmount.current.push(salesByDate[i].claimsAmount)   
          _claimsGranted.current.push(salesByDate[i].claimsGranted)
          //orderedProductSales.push(salesByDate[i].orderedProductSales.amount)    
          //orderedProductSalesB2B.push(salesByDate[i].orderedProductSalesB2B.amount)
          //temp = Number(salesByDate[i].orderedProductSales.amount) - Number(salesByDate[i].orderedProductSalesB2B.amount)
          _orderedProductSalesNotB2B.current.push(temp)  
          _ordersShipped.current.push(salesByDate[i].ordersShipped) 
          _refundRate.current.push(salesByDate[i].refundRate)
          _shippedProductSales.current.push(salesByDate[i].shippedProductSales) 
          _totalOrderItems.current.push(salesByDate[i].totalOrderItems) 
          _totalOrderItemsB2B.current.push(salesByDate[i].totalOrderItemsB2B)
          temp = Number(salesByDate[i].totalOrderItems) - Number(salesByDate[i].totalOrderItemsB2B)
          _totalOrderItemsNotB2B.current.push(temp)
          _unitsOrdered.current.push(salesByDate[i].unitsOrdered)
          _unitsOrderedB2B.current.push(salesByDate[i].unitsOrdered)
          temp = Number(salesByDate[i].unitsOrdered) - Number(salesByDate[i].unitsOrderedB2B)
          _unitsOrderedNotB2B.current.push(temp)
          _unitsRefunded.current.push(salesByDate[i].unitsRefunded)
          _unitsShipped.current.push(salesByDate[i].unitsShipped)


           //new set of reports
          _salesInTotal.current.push(salesByDate[i].orderedProductSales? salesByDate[i].orderedProductSales.amount: null)   

          _salesInAmazonBusiness.current.push(salesByDate[i].orderedProductSalesB2B? salesByDate[i].orderedProductSalesB2B.amount : null)

          temp = salesByDate[i].orderedProductSalesB2B? Number(salesByDate[i].orderedProductSales.amount) - Number(salesByDate[i].orderedProductSalesB2B.amount) : null
          _retailSales.current.push(temp);
          
      }
      
      for(let i in trafficByDate){
          _averageOfferCount.current.push(trafficByDate[i].averageOfferCount)  
          _averageParentItems.current.push(trafficByDate[i].averageParentItems)  
          _browserPageViews.current.push(trafficByDate[i].browserPageViews)  
          _browserSessions.current.push(trafficByDate[i].browserSessions)  
          _buyBoxPercentage.current.push(trafficByDate[i].buyBoxPercentage)  
          _feedbackReceived.current.push(trafficByDate[i].feedbackReceived)  
          _mobileAppPageViews.current.push(trafficByDate[i].mobileAppPageViews)  
          _mobileAppSessions.current.push(trafficByDate[i].mobileAppSessions)  
          _negativeFeedbackReceived.current.push(trafficByDate[i].negativeFeedbackReceived)  
          _orderItemSessionPercentage.current.push(trafficByDate[i].orderItemSessionPercentage)  
          _orderItemSessionPercentageB2B.current.push(trafficByDate[i].orderItemSessionPercentageB2B) 
          temp = Number(trafficByDate[i].orderItemSessionPercentage) - Number(trafficByDate[i].orderItemSessionPercentageB2B) 
          temp = (Math.round(temp * 100) / 100).toFixed(2);
          _orderItemSessionPercentageNotB2B.current.push(temp)  
          _pageViews.current.push(trafficByDate[i].pageViews) 
          _receivedNegativeFeedbackRate.current.push(trafficByDate[i].receivedNegativeFeedbackRate) 
          _sessions.current.push(trafficByDate[i].sessions) 
          _unitSessionPercentageNotB2B.current.push(trafficByDate[i].unitSessionPercentage)
          _sessionPercentage.current.push(trafficByDate[i].sessionPercentage)
          _pageViewPercentage.current.push(trafficByDate[i].pageViewsPercentage)


          //report sources 

          //---corporateBrowserSession---//
          if(salesByDate[i] == null ||salesByDate[i].totalOrderItemsB2B === 0 || trafficByDate[i].browserSessionsB2B === 0)
          {
          temp = 0;
          }
          else{
          temp = (Number(salesByDate[i].totalOrderItemsB2B) / Number(trafficByDate[i].browserSessionsB2B))
          } 
          temp = (Math.round(temp * 100) / 100).toFixed(2);
          _corporateBrowserSession.current.push(temp)

          //---retailBrowserSession---//
          if(salesByDate[i] == null ||salesByDate[i].totalOrderItems === 0 || trafficByDate[i].browserSessions === 0)
          {
          temp = 0;
          }
          else{
          temp = (Number(salesByDate[i].totalOrderItems) / Number(trafficByDate[i].browserSessions))
          } 
          temp = (Math.round(temp * 100) / 100).toFixed(2);
          _retailBrowserSession.current.push(temp)

          //---corporateMobileAppSession---//
          if(salesByDate[i] == null ||salesByDate[i].totalOrderItemsB2B === 0 || trafficByDate[i].mobileAppSessionsB2B === 0)
          {
          temp = 0;
          }
          else{
          temp = (Number(salesByDate[i].totalOrderItemsB2B) / Number(trafficByDate[i].mobileAppSessionsB2B))
          } 
          temp = (Math.round(temp * 100) / 100).toFixed(2);

          if (!isNaN(temp)) 
            _corporateMobileAppSession.current.push(temp)
          
          //---retailMobileAppSession---//
          if(salesByDate[i] == null ||salesByDate[i].totalOrderItems === 0 || trafficByDate[i].mobileAppSessions === 0)
          {
          temp = 0;
          }
          else{
          temp = (Number(salesByDate[i].totalOrderItems) / Number(trafficByDate[i].mobileAppSessions))
          } 
          temp = (Math.round(temp * 100) / 100).toFixed(2);

          if (!isNaN(temp)) 
            _retailMobileAppSession.current.push(temp)

          //---retailSession---//
          if(salesByDate[i] == null ||salesByDate[i].totalOrderItems === 0 || trafficByDate[i].sessions === 0)
          {
          temp = 0;
          }
          else{
          temp = (Number(salesByDate[i].totalOrderItems) / Number(trafficByDate[i].sessions))
          } 
          temp = (Math.round(temp * 100) / 100).toFixed(2);
          _retailSession.current.push(temp)

          //---corporateSession---//
          if(salesByDate[i] == null ||salesByDate[i].totalOrderItemsB2B === 0 || trafficByDate[i].sessionsB2B === 0)
          {
          temp = 0;
          }
          else{
          temp = (Number(salesByDate[i].totalOrderItemsB2B) / Number(trafficByDate[i].sessionsB2B))
          } 
          temp = (Math.round(temp * 100) / 100).toFixed(2);
          _corporateSession.current.push(temp)


          
          //new set of reports

          if (!isNaN(trafficByDate[i].buyBoxPercentage))
            _retailFeaturedOfferBuyBoxPercentage.current.push(trafficByDate[i].buyBoxPercentage);

          if (!isNaN(trafficByDate[i].buyBoxPercentageB2B))
            _businessFeaturedOfferBuyBoxPercentage.current.push(trafficByDate[i].buyBoxPercentageB2B);
          
      
          //---salesPerBrowserSession---//
          if(salesByDate[i] == null || salesByDate[i].orderedProductSales.amount === 0 || trafficByDate[i].browserSessions === 0)
          {
          temp = 0;
          }
          else{
              temp = (Number(salesByDate[i].orderedProductSales.amount) / Number(trafficByDate[i].browserSessions))
          } 
          temp = (Math.round(temp * 100) / 100).toFixed(2);
          _salesPerBrowserSession.current.push(temp)

          //---salesPerMobileAppPageViews---//
          if(salesByDate[i] == null ||salesByDate[i].orderedProductSales.amount === 0 || trafficByDate[i].mobileAppPageViews === 0)
          {
          temp = 0;
          }
          else{
          temp = (Number(salesByDate[i].orderedProductSales.amount) / Number(trafficByDate[i].mobileAppPageViews))
          } 
          temp = (Math.round(temp * 100) / 100).toFixed(2);
          _salesPerMobileAppPageViews.current.push(temp)
          //---END--//
          // TODO
          _strDates.current.push(moment(trafficByDate[i].date,"YYYY-MM-DD").format('MMM D, y'))
          
          // dates.push(moment(trafficByDate[i].date,"YYYY-MM-DD").format('MMM D, y'))
      }

  
      if(_strDates.current.length < 30){
          
          let diff = 30 - _strDates.current.length
          let firstDate = new Date (String(_strDates.current[0]))
          
          for(let i = diff; i > 0; i--)
          {
            firstDate.setDate(firstDate.getDate() - 1)
            // TODO _strDates.current.unshift(datepipe.transform(firstDate, 'MMM d, y' ))
            _orderItemSessionPercentage.current.unshift(0 as Number)
            _orderItemSessionPercentageB2B.current.unshift(0 as Number)
            _orderItemSessionPercentageNotB2B.current.unshift(0 as Number)
            // unitSessionPercentage.unshift(0 as ChartPoint)
            _retailMobileAppSession.current.unshift(0 as Number)
            _unitSessionPercentageNotB2B.current.unshift(0 as Number)
            _corporateMobileAppSession.current.unshift(0 as Number)
            _sessionPercentage.current.unshift(0 as Number)
            _pageViewPercentage.current.unshift(0 as Number)
            _salesPerBrowserSession.current.unshift(0 as Number)
            _corporateBrowserSession.current.unshift(0 as Number)
            _retailBrowserSession.current.unshift(0 as Number)
            _salesPerMobileAppPageViews.current.unshift(0 as Number)
            _retailSession.current.unshift(0 as Number)
            _corporateSession.current.unshift(0 as Number)

            //new set of reports
            _salesInTotal.current.unshift(0 as Number)
            _salesInAmazonBusiness.current.unshift(0 as Number)
            _retailSales.current.unshift(0 as Number)
            _retailFeaturedOfferBuyBoxPercentage.current.unshift(0 as Number)
            _businessFeaturedOfferBuyBoxPercentage.current.unshift(0 as Number)
          }
      }
  
          _filterBy.current = 'lastThirtyDays';
          _sessionType.current = 'salesInTotal';
          filter();   
    }

    function fillMissingDates(dataArray, startDate, endDate) {
      let filledData = [];
      let dateLabels = [];
      let currentDate = moment(startDate);
      const lastDate = moment(endDate);
    
      while (currentDate <= lastDate) {
        let formattedDate = currentDate.format('MMM D, y');
        dateLabels.push(formattedDate);
    
        let index = lineChartLabels.indexOf(formattedDate);
        if (index !== -1) {
          filledData.push(dataArray[index]);
        } else {
          filledData.push(0);
        }
        currentDate = currentDate.add(1, 'days');
      }
    
      return { filledData, dateLabels };
    }

    function setAverages(average1, average2, average3){
      if(_sessionType.current == 'retailMobileAppSession'){
        setAverages1(<ItemCardNew width="100%"  overrides={{Name:{children:"Average Retail Mobile App Session"}, Number:{children:average1}}}></ItemCardNew>)
        setAverages2("")
        
      }
      else if(_sessionType.current == 'corporateMobileAppSession'){
        setAverages1(<ItemCardNew width="100%"  overrides={{Name:{children:"Average Corporate Mobile App Session"}, Number:{children:average1}}}></ItemCardNew>)
        
        setAverages2("")
      }
      else if(_sessionType.current == 'retailBrowserSession'){
        setAverages1(<ItemCardNew width="100%"  overrides={{Name:{children:"Average Retail Browser Session"}, Number:{children:average1}}}></ItemCardNew>)
        
        setAverages2("");
      }
      else if(_sessionType.current == 'corporateBrowserSession'){
        setAverages1(<ItemCardNew width="100%" overrides={{Name:{children:"Average Corporate Browser Session"}, Number:{children:average1}}}></ItemCardNew>)
        
        setAverages2("");
      }
      else if(_sessionType.current == 'totalRetailSession'){
        setAverages1(<ItemCardNew width="100%"  overrides={{Name:{children:"Average Retail Session"}, Number:{children:average1}}}></ItemCardNew>)
        
        setAverages2("");
      }
      else if(_sessionType.current == 'totalCorporateSession'){
        setAverages1(<ItemCardNew width="100%" overrides={{Name:{children:"Average Corporate Session"}, Number:{children:average1}}}></ItemCardNew>)
        
        setAverages2("");
      }


      //new set of reports
      else if(_sessionType.current == 'salesInTotal'){
        setAverages1(<ItemCardNew width="33%"  overrides={{Name:{children:"Average Sales In Total"}, Number:{children:average1}}}></ItemCardNew>)
        
        setAverages2(<ItemCardNew width="33%"  overrides={{Name:{children:"Average Sales In Amazon Business"}, Number:{children:average2}}}></ItemCardNew>)
        
        setAverages3(<ItemCardNew width="33%" overrides={{Name:{children:"Average Retail Sales"}, Number:{children:average3}}}></ItemCardNew>)
      }
      else if(_sessionType.current == 'salesInAmazonBusiness'){
        setAverages1(<ItemCardNew width="100%"  overrides={{Name:{children:"Average Sales In Amazon Business"}, Number:{children:average1}}}></ItemCardNew>)
        
        setAverages2("");
      }
      else if(_sessionType.current == 'retailSales'){
        setAverages1(<ItemCardNew width="100%" overrides={{Name:{children:"Average Retail Sales"}, Number:{children:average1}}}></ItemCardNew>)
        
        setAverages2("");
      }
      else if(_sessionType.current == 'featuredOfferBuyBoxPercentage'){
        setAverages1(<ItemCardNew width="50%"  overrides={{Name:{children:"Average Retail Featured Offer Buy Box Percentage"}, Number:{children:average1}}}></ItemCardNew>)
        setAverages2(<ItemCardNew width="50%" overrides={{Name:{children:"Average Business Featured Offer Buy Box Percentage"}, Number:{children:average2}}}></ItemCardNew>)
        setAverages3("");
      
      }
      else if(_sessionType.current == 'businessFeaturedOfferBuyBoxPercentage'){
        setAverages1(<ItemCardNew width="100%" overrides={{Name:{children:"Average Business Featured Offer Buy Box Percentage"}, Number:{children:average1}}}></ItemCardNew>)
        
        setAverages2("");
      }

    }

    function filter(){
      
      setLineChartOptions( {
          responsive: true,
          showTooltips: true,
          scales: {
            y: {
                ticks: {
                    callback: function(value, index, ticks) {
                        return  value;
                    }
                }
            }
          },
          plugins: {
            legend: {
              display:true,
              title:{
                display: true
              }
            },
            tooltip: {
              callbacks: {
                  label: function (tooltipItem, data) {
                     if(tooltipItem.dataset.label.includes("Sales"))
                      return " "+tooltipItem.dataset.label + " "+currency+tooltipItem.formattedValue;

                     return " "+tooltipItem.dataset.label + " "+tooltipItem.formattedValue;
                  }
              }
          },
         
            // zoom: {
            //   zoom: {
            //     wheel: {
            //       enabled: true,
            //     },
            //     pinch: {
            //       enabled: true
            //     },
            //     mode: 'xy',
            //   },
            //   pan: {
            //     enabled: true,
            //     mode: 'xy', // 'xy' for both x and y directions
            //   },
            // },
            legend: {
              display:true,
              title:{
                display: true
              }
            },
            title: {
              display: true,
              text: 'Chart.js Line Chart',
            },
          },
        });
      
        let filterBy = _filterBy.current;
        let sessionType = _sessionType.current;
        let dat = {};
        let av1 = "";
        let av2 = "";
        let av3 = "";
        
          switch(filterBy){
              
              case 'lastSevenDays':{
                lineChartLabels = _strDates.current;
                  if(sessionType == "retailMobileAppSession"){
                      dat = [
                          {
                            label: 'Average Retail Mobile App Session',
                            data: _retailMobileAppSession.current.slice(Math.max(_retailMobileAppSession.current.length - 7, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          },
                        ];
                      av1=findAverageCurrentData(_retailMobileAppSession.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                  }
                  else if (sessionType == "corporateMobileAppSession"){
                      dat = [
                          {
                            label: 'Average Corporate Mobile App Session',
                            data: _corporateMobileAppSession.current.slice(Math.max(_corporateMobileAppSession.current.length - 7, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_corporateMobileAppSession.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                  }
                  else if (sessionType == "retailBrowserSession"){
                      
                      dat = [
                          {
                            label: 'Average Retail Browser Session',
                            data: _retailBrowserSession.current.slice(Math.max(_retailBrowserSession.current.length - 7, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_retailBrowserSession.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                  }
                  else if (sessionType == "corporateBrowserSession"){
                      
                      dat = [
                          {
                            label: 'Average Corporate Browser Session',
                            data: _corporateBrowserSession.current.slice(Math.max(_corporateBrowserSession.current.length - 7, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_corporateBrowserSession.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                  }
                  else if (sessionType == "totalCorporateSession"){
                      
                    dat = [
                        {
                          label: 'Average Corporate Session',
                          data: _corporateSession.current.slice(Math.max(_corporateSession.current.length - 7, 1)),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(_corporateSession.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                }
                else if (sessionType == "totalRetailSession"){
                    
                    dat = [
                        {
                          label: 'Average Retail Session',
                          data: _retailSession.current.slice(Math.max(_retailSession.current.length - 7, 1)),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(_retailSession.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                }

                //new set of reports
                else if (sessionType == "salesInTotal"){
                    dat = [
                        {
                          label: 'Sales in Total',
                          data: _salesInTotal.current.slice(Math.max(lineChartLabels.length - 7, 1)),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Sales in Amazon Business',
                          data: _salesInAmazonBusiness.current.slice(Math.max(_salesInAmazonBusiness.current.length - 7, 1)),
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                        {
                          label: 'Retail Sales',
                          data: _retailSales.current.slice(Math.max(_retailSales.current.length - 7, 1)),
                          borderColor: '#427f3d',
                          backgroundColor: '#427f3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=currency+findAverageCurrentData(_salesInTotal.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                    av2=currency+findAverageCurrentData(_salesInAmazonBusiness.current.slice(Math.max(_salesInAmazonBusiness.current.length - 7, 1)));
                    av3=currency+findAverageCurrentData(_retailSales.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                   
                }
                else if (sessionType == "salesInAmazonBusiness"){
                    
                    dat = [
                        {
                          label: 'Average Sales In Amazon Business',
                          data: _salesInAmazonBusiness.current.slice(Math.max(_salesInAmazonBusiness.current.length - 7, 1)),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=currency+findAverageCurrentData(_salesInAmazonBusiness.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                }
                else if (sessionType == "retailSales"){
                    
                    dat = [
                        {
                          label: 'Average Retail Sales',
                          data: _retailSales.current.slice(Math.max(_retailSales.current.length - 7, 1)),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=currency+findAverageCurrentData(_retailSales.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                }
                else if (sessionType == "featuredOfferBuyBoxPercentage"){
                    dat = [
                        {
                          label: 'Average Retail Featured Offer Buy Box Percentage',
                          data: _retailFeaturedOfferBuyBoxPercentage.current.slice(Math.max(_retailFeaturedOfferBuyBoxPercentage.current.length - 7, 1)),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Sales in Amazon Business',
                          data: _businessFeaturedOfferBuyBoxPercentage.current.slice(Math.max(_businessFeaturedOfferBuyBoxPercentage.current.length - 7, 1)),
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                      ];
                    av1=findAverageCurrentData(_retailFeaturedOfferBuyBoxPercentage.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                    av2=findAverageCurrentData(_businessFeaturedOfferBuyBoxPercentage.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                }
                else if (sessionType == "businessFeaturedOfferBuyBoxPercentage"){
                    
                    dat = [
                        {
                          label: 'Average Business Featured Offer Buy Box Percentage',
                          data: _businessFeaturedOfferBuyBoxPercentage.current.slice(Math.max(_businessFeaturedOfferBuyBoxPercentage.current.length - 7, 1)),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(_businessFeaturedOfferBuyBoxPercentage.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                }
      
                  const chartData = {
                      labels : lineChartLabels.slice(Math.max(lineChartLabels.length - 7, 1)),
                    datasets: dat,
                  };
                  setIsLoading(false);
                  setSelectValue("lastSevenDays");
                  setChartData(chartData);
                  
                  setAverages(av1,av2, av3);
                  break;
              }
              case 'lastThirtyDays':{
                  lineChartLabels = _strDates.current;
      
                  if(sessionType == "retailMobileAppSession"){
                      dat = [
                          {
                            label: 'Average Retail Mobile App Session',
                            data: _retailMobileAppSession.current.slice(Math.max(_retailMobileAppSession.current.length - 30, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          },
                        ];
                      av1=findAverageCurrentData(_retailMobileAppSession.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                  }
                  else if (sessionType == "corporateMobileAppSession"){
                      dat = [
                          {
                            label: 'Average Corporate Mobile App Session',
                            data: _corporateMobileAppSession.current.slice(Math.max(_corporateMobileAppSession.current.length - 30, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_corporateMobileAppSession.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                  }
                  else if (sessionType == "retailBrowserSession"){
                      
                      dat = [
                          {
                            label: 'Average Retail Browser Session',
                            data: _retailBrowserSession.current.slice(Math.max(_retailBrowserSession.current.length - 30, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_retailBrowserSession.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                  }
                  else if (sessionType == "corporateBrowserSession"){
                      
                      dat = [
                          {
                            label: 'Average Corporate Browser Session',
                            data: _corporateBrowserSession.current.slice(Math.max(_corporateBrowserSession.current.length - 30, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_corporateBrowserSession.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                  }

                  else if (sessionType == "totalCorporateSession"){
                        
                      dat = [
                          {
                            label: 'Average Corporate Session',
                            data: _corporateSession.current.slice(Math.max(_corporateSession.current.length - 30, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_corporateSession.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                  }
                  else if (sessionType == "totalRetailSession"){
                      
                      dat = [
                          {
                            label: 'Average Retail Session',
                            data: _retailSession.current.slice(Math.max(_retailSession.current.length - 30, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_retailSession.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                  }

                  //new set of reports
                  else if (sessionType == "salesInTotal"){
                      dat = [
                          {
                            label: 'Sales in Total',
                            data: _salesInTotal.current.slice(Math.max(lineChartLabels.length - 30, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          },
                          {
                            label: 'Sales in Amazon Business',
                            data: _salesInAmazonBusiness.current.slice(Math.max(_salesInAmazonBusiness.current.length - 30, 1)),
                            borderColor: '#007798',
                            backgroundColor: '#007798',
                            lineTension: 0.4
                          },
                          {
                            label: 'Retail Sales',
                            data: _retailSales.current.slice(Math.max(_retailSales.current.length - 30, 1)),
                            borderColor: '#427f3d',
                            backgroundColor: '#427f3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=currency+findAverageCurrentData(_salesInTotal.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                      av2=currency+findAverageCurrentData(_salesInAmazonBusiness.current.slice(Math.max(_salesInAmazonBusiness.current.length - 30, 1)));
                      av3=currency+findAverageCurrentData(_retailSales.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                    
                  }
                  else if (sessionType == "salesInAmazonBusiness"){
                      
                      dat = [
                          {
                            label: 'Average Sales In Amazon Business',
                            data: _salesInAmazonBusiness.current.slice(Math.max(_salesInAmazonBusiness.current.length - 30, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=currency+findAverageCurrentData(_salesInAmazonBusiness.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                  }
                  else if (sessionType == "retailSales"){
                      
                      dat = [
                          {
                            label: 'Average Retail Sales',
                            data: _retailSales.current.slice(Math.max(_retailSales.current.length - 30, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=currency+findAverageCurrentData(_retailSales.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                  }
                  else if (sessionType == "featuredOfferBuyBoxPercentage"){
                      dat = [
                          {
                            label: 'Average Retail Featured Offer Buy Box Percentage',
                            data: _retailFeaturedOfferBuyBoxPercentage.current.slice(Math.max(_retailFeaturedOfferBuyBoxPercentage.current.length - 30, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          },
                          {
                            label: 'Sales in Amazon Business',
                            data: _businessFeaturedOfferBuyBoxPercentage.current.slice(Math.max(_businessFeaturedOfferBuyBoxPercentage.current.length - 30, 1)),
                            borderColor: '#007798',
                            backgroundColor: '#007798',
                            lineTension: 0.4
                          },
                        ];
                      av1=findAverageCurrentData(_retailFeaturedOfferBuyBoxPercentage.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                      av2=findAverageCurrentData(_businessFeaturedOfferBuyBoxPercentage.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                  }
                  else if (sessionType == "businessFeaturedOfferBuyBoxPercentage"){
                      
                      dat = [
                          {
                            label: 'Average Business Featured Offer Buy Box Percentage',
                            data: _businessFeaturedOfferBuyBoxPercentage.current.slice(Math.max(_businessFeaturedOfferBuyBoxPercentage.current.length - 30, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_businessFeaturedOfferBuyBoxPercentage.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                  }
      
                  const chartData = {
                      labels : lineChartLabels.slice(Math.max(lineChartLabels.length - 30, 1)),
                    datasets: dat,
                  };
                  setIsLoading(false);
                  setSelectValue("lastThirtyDays");
                  setChartData(chartData);
                  
                  setAverages(av1,av2, av3);
                  break;
              }
              case 'thisWeek':{
              lineChartLabels = _strDates.current;
              let filteredData: Number[] = [];
              let y = _strDates.current[_strDates.current.length-1]
              
              const lastDate = new Date(lineChartLabels[lineChartLabels.length-1].toString());
              const date = new Date(lineChartLabels[lineChartLabels.length-1].toString());
              const day = date.getDay();
              const diff = date.getDate() - day + (day === 0 ? -6 : 1);
              let x = new Date(date.setDate(diff));
              const firstDay = moment(x).format('MMM D, y')//datepipe.transform(x, 'MMM d, y');
              
              let index = _strDates.current.indexOf(firstDay);
              if(lastDate.getDay() === 1 && _strDates.current.length > 8)
              {
                  index -= 7;
              }
              if(index === -1)
              {
                  index = 0;
              }
              console.log(firstDay);
              console.log(lastDate);
              const lastDay = moment(lastDate).format('MMM D, y')
              let firstIndex = lineChartLabels.indexOf(firstDay);
              let lastIndex = lineChartLabels.indexOf(lastDay);
              
                  if(sessionType == "retailMobileAppSession"){
                      dat = [
                          {
                            label: 'Average Retail Mobile App Session',
                            data: _retailMobileAppSession.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_retailMobileAppSession.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "corporateMobileAppSession"){
                      dat = [
                          {
                            label: 'Average Corporate Mobile App Session',
                            data: _corporateMobileAppSession.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_corporateMobileAppSession.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "retailBrowserSession"){
                      
                      dat = [
                          {
                            label: 'Average Retail Browser Session',
                            data: _retailBrowserSession.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_retailBrowserSession.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "corporateBrowserSession"){
                      
                      dat = [
                          {
                            label: 'Average Corporate Browser Session',
                            data: _corporateBrowserSession.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_corporateBrowserSession.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "totalCorporateSession"){
                        
                      dat = [
                          {
                            label: 'Average Corporate Session',
                            data: _corporateSession.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_corporateSession.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "totalRetailSession"){
                      
                      dat = [
                          {
                            label: 'Average Retail Session',
                            data: _retailSession.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_retailSession.current.slice(firstIndex,lastIndex));
                  }


                  //new set of reports
                  else if (sessionType == "salesInTotal"){
                      dat = [
                          {
                            label: 'Sales in Total',
                            data: _salesInTotal.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          },
                          {
                            label: 'Sales in Amazon Business',
                            data: _salesInAmazonBusiness.current.slice(firstIndex,lastIndex),
                            borderColor: '#007798',
                            backgroundColor: '#007798',
                            lineTension: 0.4
                          },
                          {
                            label: 'Retail Sales',
                            data: _retailSales.current.slice(firstIndex,lastIndex),
                            borderColor: '#427f3d',
                            backgroundColor: '#427f3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=currency+findAverageCurrentData(_salesInTotal.current.slice(firstIndex,lastIndex));
                      av2=currency+findAverageCurrentData(_salesInAmazonBusiness.current.slice(firstIndex,lastIndex));
                      av3=currency+findAverageCurrentData(_retailSales.current.slice(firstIndex,lastIndex));
                    
                  }
                  else if (sessionType == "salesInAmazonBusiness"){
                      
                      dat = [
                          {
                            label: 'Average Sales In Amazon Business',
                            data: _salesInAmazonBusiness.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=currency+findAverageCurrentData(_salesInAmazonBusiness.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "retailSales"){
                      
                      dat = [
                          {
                            label: 'Average Retail Sales',
                            data: _retailSales.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=currency+findAverageCurrentData(_retailSales.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "featuredOfferBuyBoxPercentage"){
                      dat = [
                          {
                            label: 'Average Retail Featured Offer Buy Box Percentage',
                            data: _retailFeaturedOfferBuyBoxPercentage.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          },
                          {
                            label: 'Sales in Amazon Business',
                            data: _businessFeaturedOfferBuyBoxPercentage.current.slice(firstIndex,lastIndex),
                            borderColor: '#007798',
                            backgroundColor: '#007798',
                            lineTension: 0.4
                          },
                        ];
                      av1=findAverageCurrentData(_retailFeaturedOfferBuyBoxPercentage.current.slice(firstIndex,lastIndex));
                      av2=findAverageCurrentData(_businessFeaturedOfferBuyBoxPercentage.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "retailFeaturedOfferBuyBoxPercentage"){
                        
                      dat = [
                          {
                            label: 'Average Retail Featured Offer Buy Box Percentage',
                            data: _retailFeaturedOfferBuyBoxPercentage.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_retailFeaturedOfferBuyBoxPercentage.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "businessFeaturedOfferBuyBoxPercentage"){
                      
                      dat = [
                          {
                            label: 'Average Business Featured Offer Buy Box Percentage',
                            data: _businessFeaturedOfferBuyBoxPercentage.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_businessFeaturedOfferBuyBoxPercentage.current.slice(firstIndex,lastIndex));
                  }
      
      
                  const chartData = {
                      labels : lineChartLabels.slice(firstIndex,lastIndex),
                    datasets: dat,
                  };
              
                  setIsLoading(false);
                  setSelectValue("thisWeek");
                  setChartData(chartData);
                  
                  setAverages(av1,av2, av3);
                  break;
              }
              case 'thisMonth':{
                lineChartLabels = _strDates.current;
                let filteredData: Number[] = [];
                const lastDate = new Date(lineChartLabels[lineChartLabels.length - 1].toString());
                const now = new Date(lineChartLabels[lineChartLabels.length - 1].toString());
                const firstDay = moment(new Date(now.getFullYear(), now.getMonth(), 1)).format('MMM D, y');
                console.log(firstDay);
                console.log(lastDate);
                let index = _strDates.current.indexOf(firstDay);
                if (index === -1) {
                    index = 0;
                }
                const lastDay = moment(lastDate).format('MMM D, y');
                let firstIndex = lineChartLabels.indexOf(firstDay);
                let lastIndex = lineChartLabels.indexOf(lastDay);
                if (firstIndex === -1) {
                    firstIndex = 0;
                }
                if (lastIndex === -1) {
                    lastIndex = lineChartLabels.length - 1;
                }
              
      
                  if(sessionType == "retailMobileAppSession"){
                      dat = [
                          {
                            label: 'Average Retail Mobile App Session',
                            data: _retailMobileAppSession.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_retailMobileAppSession.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "corporateMobileAppSession"){
                      dat = [
                          {
                            label: 'Average Corporate Mobile App Session',
                            data: _corporateMobileAppSession.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_corporateMobileAppSession.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "retailBrowserSession"){
                      
                      dat = [
                          {
                            label: 'Average Retail Browser Session',
                            data: _retailBrowserSession.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_retailBrowserSession.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "corporateBrowserSession"){
                      
                      dat = [
                          {
                            label: 'Average Corporate Browser Session',
                            data: _corporateBrowserSession.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_corporateBrowserSession.current.slice(firstIndex,lastIndex));
                  }

                  else if (sessionType == "totalCorporateSession"){
                        
                      dat = [
                          {
                            label: 'Average Corporate Session',
                            data: _corporateSession.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_corporateSession.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "totalRetailSession"){
                      
                      dat = [
                          {
                            label: 'Average Retail Session',
                            data: _retailSession.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_retailSession.current.slice(firstIndex,lastIndex));
                  }


                  //new set of reports
                  else if (sessionType == "salesInTotal"){
                      dat = [
                          {
                            label: 'Sales in Total',
                            data: _salesInTotal.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          },
                          {
                            label: 'Sales in Amazon Business',
                            data: _salesInAmazonBusiness.current.slice(firstIndex,lastIndex),
                            borderColor: '#007798',
                            backgroundColor: '#007798',
                            lineTension: 0.4
                          },
                          {
                            label: 'Retail Sales',
                            data: _retailSales.current.slice(firstIndex,lastIndex),
                            borderColor: '#427f3d',
                            backgroundColor: '#427f3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=currency+findAverageCurrentData(_salesInTotal.current.slice(firstIndex,lastIndex));
                      av2=currency+findAverageCurrentData(_salesInAmazonBusiness.current.slice(firstIndex,lastIndex));
                      av3=currency+findAverageCurrentData(_retailSales.current.slice(firstIndex,lastIndex));
                    
                  }
                  else if (sessionType == "salesInAmazonBusiness"){
                      
                      dat = [
                          {
                            label: 'Average Sales In Amazon Business',
                            data: _salesInAmazonBusiness.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=currency+findAverageCurrentData(_salesInAmazonBusiness.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "retailSales"){
                      
                      dat = [
                          {
                            label: 'Average Retail Sales',
                            data: _retailSales.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=currency+findAverageCurrentData(_retailSales.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "featuredOfferBuyBoxPercentage"){
                      dat = [
                          {
                            label: 'Average Retail Featured Offer Buy Box Percentage',
                            data: _retailFeaturedOfferBuyBoxPercentage.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          },
                          {
                            label: 'Sales in Amazon Business',
                            data: _businessFeaturedOfferBuyBoxPercentage.current.slice(firstIndex,lastIndex),
                            borderColor: '#007798',
                            backgroundColor: '#007798',
                            lineTension: 0.4
                          },
                        ];
                      av1=findAverageCurrentData(_retailFeaturedOfferBuyBoxPercentage.current.slice(firstIndex,lastIndex));
                      av2=findAverageCurrentData(_businessFeaturedOfferBuyBoxPercentage.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "retailFeaturedOfferBuyBoxPercentage"){
                        
                      dat = [
                          {
                            label: 'Average Retail Featured Offer Buy Box Percentage',
                            data: _retailFeaturedOfferBuyBoxPercentage.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_retailFeaturedOfferBuyBoxPercentage.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "businessFeaturedOfferBuyBoxPercentage"){
                      
                      dat = [
                          {
                            label: 'Average Business Featured Offer Buy Box Percentage',
                            data: _businessFeaturedOfferBuyBoxPercentage.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_businessFeaturedOfferBuyBoxPercentage.current.slice(firstIndex,lastIndex));
                  }
      
                  const chartData = {
                      labels : lineChartLabels.slice(firstIndex,lastIndex),
                    datasets: dat,
                  };
                  console.log(chartData);
                  setIsLoading(false);
                  setSelectValue("thisMonth");
                  setChartData(chartData);
                  
                  setAverages(av1,av2, av3);break;
              }
              //ANDREW: THIS ALGO IS TO SHOW ALL DATES REGARDLESS IF THE VALUE IS ZERO
              case 'lastOneYear': {
                lineChartLabels = _strDates.current;
                let filteredData = [];
                const lastDate = new Date(lineChartLabels[lineChartLabels.length - 1].toString());
                const firstDayOfYear = moment(new Date(lastDate.getFullYear() - 1, lastDate.getMonth(), lastDate.getDate())).format('MMM D, y');
              
                let firstIndex = lineChartLabels.indexOf(firstDayOfYear);
                let lastIndex = lineChartLabels.length;
              
                if (firstIndex === -1) {
                  firstIndex = 0;
                }
              
                const { filledData: retailMobileAppSessionData, dateLabels } = fillMissingDates(_retailMobileAppSession.current, firstDayOfYear, lastDate);
                
                if (sessionType == "retailMobileAppSession") {
                  dat = [
                    {
                      label: 'Average Retail Mobile App Session',
                      data: retailMobileAppSessionData,
                      borderColor: '3c3e3d',
                      backgroundColor: '3c3e3d',
                      lineTension: 0.4
                    }
                  ];
                  av1 = findAverageCurrentData(retailMobileAppSessionData);
                }else if (sessionType == "corporateMobileAppSession") {
                  const { filledData: corporateMobileAppSessionData } = fillMissingDates(_corporateMobileAppSession.current, firstDayOfYear, lastDate);
                  dat = [
                    {
                      label: 'Average Corporate Mobile App Session',
                      data: corporateMobileAppSessionData,
                      borderColor: '3c3e3d',
                      backgroundColor: '3c3e3d',
                      lineTension: 0.4
                    }
                  ];
                  av1 = findAverageCurrentData(corporateMobileAppSessionData);
                } else if (sessionType == "retailBrowserSession") {
                  const { filledData: retailBrowserSessionData } = fillMissingDates(_retailBrowserSession.current, firstDayOfYear, lastDate);
                  dat = [
                    {
                      label: 'Average Retail Browser Session',
                      data: retailBrowserSessionData,
                      borderColor: '3c3e3d',
                      backgroundColor: '3c3e3d',
                      lineTension: 0.4
                    }
                  ];
                  av1 = findAverageCurrentData(retailBrowserSessionData);
                } else if (sessionType == "corporateBrowserSession") {
                  const { filledData: corporateBrowserSessionData } = fillMissingDates(_corporateBrowserSession.current, firstDayOfYear, lastDate);
                  dat = [
                    {
                      label: 'Average Corporate Browser Session',
                      data: corporateBrowserSessionData,
                      borderColor: '3c3e3d',
                      backgroundColor: '3c3e3d',
                      lineTension: 0.4
                    }
                  ];
                  av1 = findAverageCurrentData(corporateBrowserSessionData);
                }
                else if (sessionType == "totalCorporateSession") {
                  const { filledData: totalCorporateSessionData } = fillMissingDates(_corporateSession.current, firstDayOfYear, lastDate);
                  dat = [
                    {
                      label: 'Average Corporate Session',
                      data: totalCorporateSessionData,
                      borderColor: '3c3e3d',
                      backgroundColor: '3c3e3d',
                      lineTension: 0.4
                    }
                  ];
                  av1 = findAverageCurrentData(totalCorporateSessionData);
                } else if (sessionType == "totalRetailSession") {
                  const { filledData: totalRetailSessionData } = fillMissingDates(_retailSession.current, firstDayOfYear, lastDate);
                  dat = [
                    {
                      label: 'Average Retail Session',
                      data: totalRetailSessionData,
                      borderColor: '3c3e3d',
                      backgroundColor: '3c3e3d',
                      lineTension: 0.4
                    }
                  ];
                  av1 = findAverageCurrentData(totalRetailSessionData);
                }

                
                //new set of reports
                else if (sessionType == "salesInTotal"){
                    const { filledData: salesInTotalData } = fillMissingDates(_salesInTotal.current, firstDayOfYear, lastDate);
                    const { filledData: salesInAmazonBusiness } = fillMissingDates(_salesInAmazonBusiness.current, firstDayOfYear, lastDate);
                    const { filledData: retailSalesData } = fillMissingDates(_retailSales.current, firstDayOfYear, lastDate);

                    dat = [
                        {
                          label: 'Sales in Total',
                          data: salesInTotalData,
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Sales in Amazon Business',
                          data:salesInAmazonBusiness,
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                        {
                          label: 'Retail Sales',
                          data: retailSalesData,
                          borderColor: '#427f3d',
                          backgroundColor: '#427f3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=currency+findAverageCurrentData(salesInTotalData);
                    av2=currency+findAverageCurrentData(salesInAmazonBusiness);
                    av3=currency+findAverageCurrentData(retailSalesData);
                  
                }
                else if (sessionType == "salesInAmazonBusiness"){
                    const { filledData: salesInAmazonBusiness } = fillMissingDates(_salesInAmazonBusiness.current, firstDayOfYear, lastDate);
                    dat = [
                        {
                          label: 'Average Sales In Amazon Business',
                          data: salesInAmazonBusiness,
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=currency+findAverageCurrentData(salesInAmazonBusiness);
                }
                else if (sessionType == "retailSales"){
                    const { filledData: retailSalesData } = fillMissingDates(_retailSales.current, firstDayOfYear, lastDate);
                    dat = [
                        {
                          label: 'Average Retail Sales',
                          data: retailSalesData,
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=currency+findAverageCurrentData(retailSalesData);
                }
                else if (sessionType == "featuredOfferBuyBoxPercentage"){
                  const { filledData: retailFeaturedOfferBuyBoxPercentData } = fillMissingDates(_retailFeaturedOfferBuyBoxPercentage.current, firstDayOfYear, lastDate);
                  const { filledData: businessFeaturedOfferBuyBoxPercentData } = fillMissingDates(_businessFeaturedOfferBuyBoxPercentage.current, firstDayOfYear, lastDate);
                    
                  dat = [
                        {
                          label: 'Average Retail Featured Offer Buy Box Percentage',
                          data: retailFeaturedOfferBuyBoxPercentData,
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Sales in Amazon Business',
                          data: businessFeaturedOfferBuyBoxPercentData,
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                      ];
                    av1=findAverageCurrentData(retailFeaturedOfferBuyBoxPercentData);
                    av2=findAverageCurrentData(businessFeaturedOfferBuyBoxPercentData);
                }
                else if (sessionType == "retailFeaturedOfferBuyBoxPercentage"){
                    const { filledData: retailFeaturedOfferBuyBoxPercentData } = fillMissingDates(_retailFeaturedOfferBuyBoxPercentage.current, firstDayOfYear, lastDate);
                    dat = [
                        {
                          label: 'Average Retail Featured Offer Buy Box Percentage',
                          data: retailFeaturedOfferBuyBoxPercentData,
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(retailFeaturedOfferBuyBoxPercentData);
                }
                else if (sessionType == "businessFeaturedOfferBuyBoxPercentage"){
                    const { filledData: businessFeaturedOfferBuyBoxPercentData } = fillMissingDates(_businessFeaturedOfferBuyBoxPercentage.current, firstDayOfYear, lastDate);
                    dat = [
                        {
                          label: 'Average Business Featured Offer Buy Box Percentage',
                          data: businessFeaturedOfferBuyBoxPercentData,
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(businessFeaturedOfferBuyBoxPercentData);
                }
              
                const chartData = {
                  labels: dateLabels,
                  datasets: dat,
                };
                console.log(chartData);
                setIsLoading(false);
                setSelectValue("lastOneYear");
                setChartData(chartData);
                setAverages(av1, av2, av3);
                break;
              }
              
              case 'lastTwoYears': {
                lineChartLabels = _strDates.current;
                let filteredData = [];
                const lastDate = new Date(lineChartLabels[lineChartLabels.length - 1].toString());
                const firstDayOfTwoYearsAgo = moment(new Date(lastDate.getFullYear() - 2, lastDate.getMonth(), lastDate.getDate())).format('MMM D, y');
              
                let firstIndex = lineChartLabels.indexOf(firstDayOfTwoYearsAgo);
                let lastIndex = lineChartLabels.length;
              
                if (firstIndex === -1) {
                  firstIndex = 0;
                }
              
                const { filledData: retailMobileAppSessionData, dateLabels } = fillMissingDates(_retailMobileAppSession.current, firstDayOfTwoYearsAgo, lastDate);
                if (sessionType == "retailMobileAppSession") {
                  dat = [
                    {
                      label: 'Average Retail Mobile App Session',
                      data: retailMobileAppSessionData,
                      borderColor: '3c3e3d',
                      backgroundColor: '3c3e3d',
                      lineTension: 0.4
                    }
                  ];
                  av1 = findAverageCurrentData(retailMobileAppSessionData);
                } else if (sessionType == "corporateMobileAppSession") {
                  const { filledData: corporateMobileAppSessionData } = fillMissingDates(_corporateMobileAppSession.current, firstDayOfTwoYearsAgo, lastDate);
                  dat = [
                    {
                      label: 'Average Corporate Mobile App Session',
                      data: corporateMobileAppSessionData,
                      borderColor: '3c3e3d',
                      backgroundColor: '3c3e3d',
                      lineTension: 0.4
                    }
                  ];
                  av1 = findAverageCurrentData(corporateMobileAppSessionData);
                } else if (sessionType == "retailBrowserSession") {
                  const { filledData: retailBrowserSessionData } = fillMissingDates(_retailBrowserSession.current, firstDayOfTwoYearsAgo, lastDate);
                  dat = [
                    {
                      label: 'Average Retail Browser Session',
                      data: retailBrowserSessionData,
                      borderColor: '3c3e3d',
                      backgroundColor: '3c3e3d',
                      lineTension: 0.4
                    }
                  ];
                  av1 = findAverageCurrentData(retailBrowserSessionData);
                } else if (sessionType == "corporateBrowserSession") {
                  const { filledData: corporateBrowserSessionData } = fillMissingDates(_corporateBrowserSession.current, firstDayOfTwoYearsAgo, lastDate);
                  dat = [
                    {
                      label: 'Average Corporate Browser Session',
                      data: corporateBrowserSessionData,
                      borderColor: '3c3e3d',
                      backgroundColor: '3c3e3d',
                      lineTension: 0.4
                    }
                  ];
                  av1 = findAverageCurrentData(corporateBrowserSessionData);
                }

                else if (sessionType == "totalCorporateSession") {
                  const { filledData: totalCorporateSessionData } = fillMissingDates(_corporateSession.current, firstDayOfTwoYearsAgo, lastDate);
                  dat = [
                    {
                      label: 'Average Corporate Session',
                      data: totalCorporateSessionData,
                      borderColor: '3c3e3d',
                      backgroundColor: '3c3e3d',
                      lineTension: 0.4
                    }
                  ];
                  av1 = findAverageCurrentData(totalCorporateSessionData);
                } else if (sessionType == "totalRetailSession") {
                  const { filledData: totalRetailSessionData } = fillMissingDates(_retailSession.current, firstDayOfTwoYearsAgo, lastDate);
                  dat = [
                    {
                      label: 'Average Retail Session',
                      data: totalRetailSessionData,
                      borderColor: '3c3e3d',
                      backgroundColor: '3c3e3d',
                      lineTension: 0.4
                    }
                  ];
                  av1 = findAverageCurrentData(totalRetailSessionData);
                }

                
                //new set of reports
                else if (sessionType == "salesInTotal"){
                    const { filledData: salesInTotalData } = fillMissingDates(_salesInTotal.current, firstDayOfTwoYearsAgo, lastDate);
                    const { filledData: salesInAmazonBusiness } = fillMissingDates(_salesInAmazonBusiness.current, firstDayOfTwoYearsAgo, lastDate);
                    const { filledData: retailSalesData } = fillMissingDates(_retailSales.current, firstDayOfTwoYearsAgo, lastDate);

                    dat = [
                        {
                          label: 'Sales in Total',
                          data: salesInTotalData,
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Sales in Amazon Business',
                          data:salesInAmazonBusiness,
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                        {
                          label: 'Retail Sales',
                          data: retailSalesData,
                          borderColor: '#427f3d',
                          backgroundColor: '#427f3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=currency+findAverageCurrentData(salesInTotalData);
                    av2=currency+findAverageCurrentData(salesInAmazonBusiness);
                    av3=currency+findAverageCurrentData(retailSalesData);
                  
                }
                else if (sessionType == "salesInAmazonBusiness"){
                    const { filledData: salesInAmazonBusiness } = fillMissingDates(_salesInAmazonBusiness.current, firstDayOfTwoYearsAgo, lastDate);
                    dat = [
                        {
                          label: 'Average Sales In Amazon Business',
                          data: salesInAmazonBusiness,
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=currency+findAverageCurrentData(salesInAmazonBusiness);
                }
                else if (sessionType == "retailSales"){
                    const { filledData: retailSalesData } = fillMissingDates(_retailSales.current, firstDayOfTwoYearsAgo, lastDate);
                    dat = [
                        {
                          label: 'Average Retail Sales',
                          data: retailSalesData,
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=currency+findAverageCurrentData(retailSalesData);
                }
                else if (sessionType == "featuredOfferBuyBoxPercentage"){
                  const { filledData: retailFeaturedOfferBuyBoxPercentData } = fillMissingDates(_retailFeaturedOfferBuyBoxPercentage.current, firstDayOfTwoYearsAgo, lastDate);
                  const { filledData: businessFeaturedOfferBuyBoxPercentData } = fillMissingDates(_businessFeaturedOfferBuyBoxPercentage.current, firstDayOfTwoYearsAgo, lastDate);
                    
                  dat = [
                        {
                          label: 'Average Retail Featured Offer Buy Box Percentage',
                          data: retailFeaturedOfferBuyBoxPercentData,
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Sales in Amazon Business',
                          data: businessFeaturedOfferBuyBoxPercentData,
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                      ];
                    av1=findAverageCurrentData(retailFeaturedOfferBuyBoxPercentData);
                    av2=findAverageCurrentData(businessFeaturedOfferBuyBoxPercentData);
                }
                else if (sessionType == "retailFeaturedOfferBuyBoxPercentage"){
                    const { filledData: retailFeaturedOfferBuyBoxPercentData } = fillMissingDates(_retailFeaturedOfferBuyBoxPercentage.current, firstDayOfTwoYearsAgo, lastDate);
                    dat = [
                        {
                          label: 'Average Retail Featured Offer Buy Box Percentage',
                          data: retailFeaturedOfferBuyBoxPercentData,
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(retailFeaturedOfferBuyBoxPercentData);
                }
                else if (sessionType == "businessFeaturedOfferBuyBoxPercentage"){
                    const { filledData: businessFeaturedOfferBuyBoxPercentData } = fillMissingDates(_businessFeaturedOfferBuyBoxPercentage.current, firstDayOfTwoYearsAgo, lastDate);
                    dat = [
                        {
                          label: 'Average Business Featured Offer Buy Box Percentage',
                          data: businessFeaturedOfferBuyBoxPercentData,
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(businessFeaturedOfferBuyBoxPercentData);
                }
              
              
                const chartData = {
                  labels: dateLabels,
                  datasets: dat,
                };
                console.log(chartData);
                setIsLoading(false);
                setSelectValue("lastTwoYears");
                setChartData(chartData);
                setAverages(av1, av2, av3);
                break;
              }
            
          }
      }

      function handleDropdownChange (e){
    
          _filterBy.current = e.target.value;
          filter();
          
      }
      
      function handleDropdownChangeSessionType  (e){
          
          _sessionType.current = e.target.value;
          filter();
          
      }
    
    return (
        
        
                    <Flex
                        gap="32px"
                        direction="column"
                        width="100%"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        overflow="hidden"
                        position="relative"
                        
                        >
                        <Flex
                            gap="8px"
                            direction="column"
                            width="unset"
                            height="unset"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            
                        >
                            <Text
                            fontFamily="Inter"
                            fontSize="32px"
                            fontWeight="500"
                            color="rgba(13,26,38,1)"
                            lineHeight="40px"
                            textAlign="left"
                            display="block"
                            direction="column"
                            justifyContent="unset"
                            width="unset"
                            height="unset"
                            gap="unset"
                            alignItems="unset"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                            children="Sales Report"
                            
                            ></Text>
                        </Flex>
                        <Flex
                            gap="32px"
                            direction="row"
                            width="unset"
                            height="unset"
                            justifyContent="center"
                            alignItems="flex-start"
                            shrink="0"
                            alignSelf="stretch"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            
                        >
                            
                                {averages1}
                                
                            
                               {averages2}

                               {averages3}
                               
                           
                        </Flex>
                        
                    
                        <Flex
                        gap="48px"
                        direction="column"
                        width="100%"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        position="relative"
                        border="1px SOLID rgba(174,179,183,1)"
                        borderRadius="12px"
                        padding="31px 31px 31px 31px"
                        backgroundColor="rgba(255,255,255,1)"
                    
                        >
                            <Flex
                                gap="13%" //TODO make it 32px if filter by date and asin is back
                                direction="row"
                                width="unset"
                                height="unset"
                                justifyContent="flex-start" //TODO make it space-between if filter by date and asin is back
                                alignItems="flex-start"
                                shrink="0"
                                alignSelf="stretch"
                                position="relative"
                                padding="0px 7px 0px 40px"
                                
                            >
                            
                            {  /*<Flex
                            gap="4px"
                            direction="column"
                            width="unset"
                            height="72px"
                            justifyContent="flex-start"
                            alignItems="center"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            
                            >
                                <SelectField
                                    width="300px"
                                    height="unset"
                                    label="Type"
                                    shrink="0"
                                    size="default"
                                    isDisabled={false}
                                    labelHidden={false}
                                    variation="default"
                                    onChange={moveAsinView}
                                    defaultValue="date"
                                >
                                  <option value="date">By Date</option>
                                  <option value="asin">By ASIN</option>
                                </SelectField>
                            </Flex>  */}
                            
                                <Flex
                                gap="4px"
                                direction="column"
                                width="unset"
                                height="72px"
                                justifyContent="flex-start"
                                alignItems="center"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                
                                >
                                    <SelectField
                                        width="300px"
                                        height="unset"
                                        label="Type"
                                        shrink="0"
                                        size="default"
                                        isDisabled={false}
                                        labelHidden={false}
                                        variation="default"
                                        value={subscriptionPlan || 'loading'}
                                        label="Filter By Date" value={selectValue}  onChange={handleDropdownChange}
                                      >
                                        {subscriptionLoading ? (
                                              <option value="loading" disabled>
                                                  <Loader size="small" /> Loading...
                                              </option>
                                          ) : (
                                              <>
                                                <option value="lastSevenDays">Last 7 Days</option>
                                                <option value="lastThirtyDays">Last 30 Days</option>
                                                <option value="thisWeek">This Week</option>
                                                <option value="thisMonth">This Month</option>
                                                {subscriptionPlan !== "Free Tier" && (
                                                  <>
                                                    <option value="lastOneYear">Last 1 Year</option>
                                                    <option value="lastTwoYears">Last 2 Years</option>
                                                  </>
                                                )}
                                          </>
                                        )}
                                      </SelectField>
                                </Flex>
                                <Flex
                                gap="4px"
                                direction="column"
                                width="unset"
                                height="72px"
                                justifyContent="flex-start"
                                alignItems="center"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                
                                >
                                    <SelectField
                                        width="320px"
                                        height="unset"
                                        label="Filter By"
                                        
                                        shrink="0"
                                        size="default"
                                        isDisabled={false}
                                        labelHidden={false}
                                        variation="default"
                                    
                                        onChange={handleDropdownChange}
                                       
                                        label="Session Type"  onChange={handleDropdownChangeSessionType}
                                      >
                                          { /* <option value="retailMobileAppSession">Retail Mobile App Session</option>
                                          <option value="corporateMobileAppSession">Corporate Mobile App Session</option>
                                          <option value="retailBrowserSession">Retail Browser Session</option>
                                          <option value="corporateBrowserSession">Corporate Browser Session</option>
                                          <option value="totalRetailSession">Total Retail Session</option>
                                          <option value="totalCorporateSession">Total Corporate Session</option> */ }

                                          <option value="salesInTotal">Sales In Total</option>
                                          <option value="featuredOfferBuyBoxPercentage">Featured Offer Buy Box %</option>
                                      </SelectField>
                                </Flex>
                               {/* <Flex
                                gap="4px"
                                direction="column"
                                width="unset"
                                height="72px"
                                justifyContent="flex-start"
                                alignItems="center"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                
                                >
                                    <SelectField
                                        width="320px"
                                        height="unset"
                                        label="Sort By"
                                        placeholder="Unit Session %"
                                        shrink="0"
                                        size="default"
                                        isDisabled={false}
                                        labelHidden={false}
                                        variation="default"
                                        
                                    ></SelectField>
                                </Flex>  */}
                            </Flex>
                            
                             {isLoading ? <Flex/> : <Line options={lineChartOptions} data={chartData} width="400" height="100" />}
           
                            
            
                  
                        </Flex> 
                    </Flex>          
                
        
    );
}

export default withAuthenticator(SalesReport);