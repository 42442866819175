import React from 'react'
import { useState,useEffect,useRef } from 'react';
import { Amplify, Auth, API,Storage,Cache  } from 'aws-amplify';
import {Flex, Button,Text} from '@aws-amplify/ui-react';

import studioTheme from '../ui-components/studioTheme';
import SideBar from '../ui-components/SideBar';
import AccountAccountInformation from '../ui-components/Forecasts'; 
import DeleteModal from '../ui-components/DeleteModal'; 
import { withAuthenticator } from '@aws-amplify/ui-react';
import awsconfig from '../aws-exports';
import { getUser,getIngestJob, getForecastJob, forecastsByDate,getStripeCustomer } from '../graphql/queries';
import { createImportSpecifier } from 'typescript';

import { listForecastJobs, listJobs } from '../graphql/queries';
import { createForecastJob, createIngestJob, createJob, updateForecastJob } from '../graphql/mutations';
import ButtonWithIcon2 from '../ui-components/ButtonWithIcon2';
import ForecastsDataRowTitle from '../ui-components/ForecastsDataRowTitle';
import ForecastsDataRow from '../ui-components/ForecastsDataRow';

import { DateTime } from "luxon";
import Modal from 'react-modal'
import ForecastsJobMetrics from '../ui-components/ForecastsJobMetrics';
import ForecastView from '../components/forecast.tsx'
import CreateForecast from '../components/create-forecast.tsx';
import ForecastJobMetricsModal from '../ui-components/ForecastJobMetricsModal.jsx';
import GeneralFooter from '../ui-components/GeneralFooter.jsx';

Amplify.configure(awsconfig);

export type CreateIngestJobResponse = {
    createIngestJob: {
        id:string
    }
}

export type CreateForecastJobResponse = {
    createForecastJob: {
        id:string
    }
}



function ForecastList({forecastIdd}) {
    const [jobs, setJobs] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [metricsModalIsOpen, setMetricsModalIsOpen] = useState(false);
    const [forecastModalIsOpen, setForecastModalIsOpen] = useState(false);
    const [deleteForecastModalIsOpen, setDeleteForecastModalIsOpen] = useState(false);
    const [createForecastModalIsOpen, setCreateForecastModalIsOpen] = useState(false);
    const [metrics, setMetrics] = useState({Algorithm:"",MAPE:"",MASE:"",RMSE:"",WAPE:""});
    const [forecastJobId, setForecastJobId] = useState("");
    const [ingestJobId, setIngestJobId] = useState("");
    
    const [previous, setPrevious] = useState(<Flex/>);
    const [next, setNext] = useState(<Flex/>);
    const _nextToken= useRef<String>("");
    const _previousPages= useRef(new Array());
    const _toDelete= useRef<String>("");
    const stripe= useRef(new Array());
    
    useEffect(() => {
        fetchJobs();
        
    },[]);// <-- Have to pass in [] here or is called on every render
    
    async function viewMetrics(forecastJobId){
        let url = forecastJobId + "/metric.json";
        
        const result = await Storage.vault.get(url, { download: true });
        const metrics = await new Response(result.Body).json();
        
        const condensedMetricData = {
            Algorithm: metrics.Algorithm,
            metric:  getCondition(metrics),
            MAPE: getCondition(metrics) ? metrics.metrics[0].Metrics.ErrorMetrics[0].MAPE : "",
            MASE: getCondition(metrics) ? metrics.metrics[0].Metrics.ErrorMetrics[0].MASE : "",
            RMSE: getCondition(metrics) ? metrics.metrics[0].Metrics.ErrorMetrics[0].RMSE : "",
            WAPE: getCondition(metrics) ? metrics.metrics[0].Metrics.ErrorMetrics[0].WAPE : ""
          }; 
          
        setMetrics(condensedMetricData);
        setMetricsModalIsOpen(true);
    }

    async function deleteForecastJob(forecastId){
        console.log('will delete '+forecastId)
        await API.graphql({
            query: updateForecastJob,
            variables: {
              input: {
                id: forecastId,
                isDeleted:"true"
              },
            },
          });
    }

    function getCondition(metricData){
        return metricData.hasOwnProperty("metrics") && metricData.metrics.length > 1 && metricData.metrics[0].Metrics.hasOwnProperty("ErrorMetrics");
    }

    function closeMetrics(){
        setMetricsModalIsOpen(false)
    }
    
    function viewForecast(fJobId, iJobId){
        setForecastJobId(fJobId);
        setIngestJobId(iJobId);
        setForecastModalIsOpen(true)
        
    }

    function closeForecast(){
        setForecastModalIsOpen(false)
    }

    //for after creating a new forecast - should be done with a graphql subscription instead rather than a refresh
    function closeForecastRefresh(){
        setForecastModalIsOpen(false)
        window.location.href="/forecasts";
    }
    
    function deleteForecast(id){
        setDeleteForecastModalIsOpen(true)
        _toDelete.current = id;
    
    }

    function confirmDeleteForecast(e){
        
        deleteForecastJob(_toDelete.current)
        setDeleteForecastModalIsOpen(false)
        _toDelete.current = "";
        window.location.href="/forecasts";
    }

    function closeDeleteForecast(){
        setDeleteForecastModalIsOpen(false)
    }
    
    function newForecast(){
        setCreateForecastModalIsOpen(true)
        

        
    }

    function closeNewForecast(){
        setCreateForecastModalIsOpen(false)
    }

    function nextClick(){
        
        fetchJobs();
    }

    function previousClick(){
        _nextToken.current = _previousPages.current.pop();
        console.log(_nextToken.current);
        fetchJobs();
    }

    async function fetchJobs() {

        
        let user = await Auth.currentAuthenticatedUser({
                bypassCache: false
            })
        
        try {
            //const dbUser:any = await API.graphql({ query: getUser, variables:{id:user.attributes.email}});
            console.log(user.attributes.email)
            const result = await API.graphql({ query: getUser , variables:{id:user.attributes.email}});
          
            if(result['data'] && result['data'].getUser){
                
                var response;
                
                response = await API.graphql({
                    query: getStripeCustomer
                    ,variables : {"email": user.attributes.email, "stripeCustomerId": user.attributes.stripeId}
                })

                const stripeData = JSON.parse(response["data"]["getStripeCustomer"]);

                var subscriber = null;
                //find the exact plan they are on currently
                if(stripeData['body'] && stripeData['body']['data']){
                for(var i = 0; i < stripeData['body']['data'].length;i++){
                    let currentCustomer = stripeData['body']['data'][i];
                    if(currentCustomer['customer'] == result['data'].getUser["stripeId"]){
                    //found the current plan 
                    // currentPlan = stripeData['body']['data'][i]['plan'];
                    subscriber =  stripeData["body"]["data"][i];
                    
                    var current = new Date(); 
                    var fiveMinutes = new Date(current.getTime() + 300000);
                    console.log("five mins ",fiveMinutes)
                    stripe.current = subscriber
                    console.log("stripe",stripe)
                    
                    }
                }
                }
                // if(!subscriber){
                //   navigate("/accounts");
                // }
                // if(stripeData['body']['data'] != null && stripeData['body']['data'][0] !=null){
                //   console.log('stripeData',stripeData);
                //   let plan = stripeData['body']['data'][0]['plan']['product']
                //   result['data'].getUser['plan'] = plan;
                
                
                // }
            }




            let vars = {};
            console.log(_previousPages.current.length)
            if(_previousPages.current.length > 0){
                setPrevious(<Button onClick={previousClick}>Previous</Button>);
            }
            else {
                setPrevious(<Flex/>);
            }
            if (_nextToken.current == "" && _previousPages.current.length == 0) {
                _previousPages.current.push("");
                vars = {isDeleted:"false", limit:5, sortDirection:'DESC'}
            }
            else if(_nextToken.current != "" && _previousPages.current.length == 1){
                vars = {isDeleted:"false", limit:5, sortDirection:'DESC',nextToken:_nextToken.current}
            }
            else if(_nextToken.current != ""){
                _previousPages.current.push(_nextToken.current);
                vars = {isDeleted:"false", limit:5, sortDirection:'DESC',nextToken:_nextToken.current}
            }

            var forecastsByDateQuery=`
            query ForecastsByDate(
                $isDeleted: String!
                $createdAt: ModelStringKeyConditionInput
                $sortDirection: ModelSortDirection
                $filter: ModelForecastJobFilterInput
                $limit: Int
                $nextToken: String
              ) {
                forecastsByDate(
                  isDeleted: $isDeleted
                  createdAt: $createdAt
                  sortDirection: $sortDirection
                  filter: $filter
                  limit: $limit
                  nextToken: $nextToken
                ) {
                  items {
                    createdAt
                    endIngest
                    id
                    isDeleted
                    market
                    resultFile {
                        region
                        key
                        bucket
                    }
                    ingestJobId
                    forecastParam {
                        ForecastHorizon
                    }
                    job {
                        forecastJobStatus
                    }
                    sellingPartnerId
                    startIngest
                        owner
                        __typename
                    }
                    nextToken
                    __typename
                }
              }
            
            `
            
            const jobs = await API.graphql({ query: forecastsByDateQuery, variables:vars});
            let jobsList:any[] = [];
            
            console.log(jobs["data"]["forecastsByDate"]);
            
            if(jobs["data"]["forecastsByDate"]["nextToken"]){
                
                _nextToken.current = (jobs["data"]["forecastsByDate"]["nextToken"]);
                
                setNext(<Button onClick={nextClick}>Next</Button>);
            }
            else{
                setNext(<Flex/>);
            }
            for(let i = 0;i<jobs["data"]["forecastsByDate"]["items"].length;i++){
                let job = jobs["data"]["forecastsByDate"]["items"][i];
                jobsList[i] = job;
                //     const ingestJob = await API.graphql({ query: getIngestJob,variables:{id:job['ingestJobID']} });
            //     const forecastJob = await API.graphql({ query: getForecastJob,variables:{id:job['forecastJobID']} });
            //     let jobb = job;
            //     job['forecastJob'] = forecastJob["data"]["getForecastJob"];
            //     job['ingestJob'] = ingestJob["data"]["getIngestJob"];
             }
            
            setJobs(
                jobsList
            )
            
            setIsLoading(false);

        } catch (err) {
          console.log('error fetching forecasts',err);
        }
    }

    
    
    const marketplaces  = {
        
        'ATVPDKIKX0DER' : 'US',
         'A1AM78C64UM0Y8':'MX',
         'A2EUQ1WTGCTBG2':'CA',
         'A2Q3Y263D00KWC':'BR',
        
         'A1RKKUPIHCS9HS':'ES',
         'A1F83G8C2ARO7P':'GB',
         'A13V1IB3VIYZZH':'FR',
         'A1805IZSGTT6HS':'NL',
         'A1PA6795UKMFR9':'DE',
         'APJ6JRA9NG5V4':'IT',
         'A2NODRKZP88ZB9':'SE',
         'A1C3SOZRARQ6R3':'PL',
         'ARBP9OOSHTCHU':'EG',
         'A33AVAJ2PDY3EV':'TR',
         'A17E79C6D8DWNP':'SA',
         'A2VIGQ35RCS4UG':'AE',
         'A21TJRUUN4KGV':'IN',
         'AMEN7PMS3EDWL': "EU",
        
         'AA19VAU5U5O7RUS':'SG',
         'A39IBJ37TRP1C6':'AU',
         'A1VC38T7YXB528':'JP'
    }
    const rows:React.JSX.Element[] =[];

    if(!isLoading){

            var create=<Flex></Flex> 
            //Stripe cache is updated every 5 minutes in App.tsx
            console.log("stripe2",stripe)
            // if(stripe.current && stripe.current['plan'] && stripe.current['plan']['product'] && stripe.current['plan']['product'] == "prod_PESLbIINOuZkuk" && (stripe.current["status"] == "active" || stripe.current["status"] == "trialing") ){ 
                create = <ButtonWithIcon2
                    display="flex"
                    gap="12px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="center"
                    shrink="0"
                    position="relative"
                    border="1px SOLID rgba(0,0,0,0)"
                    borderRadius="4px"
                    padding="7px 15px 7px 15px"
                    state="Default"
                    type="NewForecast"
                    overrides = {
                    {"ButtonWithIcon2":
                    {onClick: () => newForecast()}}
                    }
                ></ButtonWithIcon2>
            // }

        for(let j = 0; j < jobs.length; j++){
            if(jobs[j] !=null){
                console.log(jobs[j].resultFile)
                const forecastLength = jobs[j].forecastParam.ForecastHorizon;
                const forecastEnd = DateTime.fromISO(jobs[j].endIngest).plus({ days: forecastLength }).toLocaleString();
                const jobStatus = jobs[j].resultFile ? "Done" : "Pending";
                const jobColour = jobs[j].resultFile ? "success" : "warning";
                const viewButton = jobs[j].resultFile ? "View" : ""; 
                rows.push(
                    <ForecastsDataRow
                    overrides={
                        {
                            label38764959:
                            {"children":jobs[j].sellingPartnerId},
                            label38764960:
                            {"children":marketplaces[jobs[j].market]},
                            label38764961:
                            {"children":DateTime.fromISO(jobs[j].startIngest).toLocaleString()},
                            label38764962:
                            {"children":DateTime.fromISO(jobs[j].endIngest).toLocaleString()},
                            label38764963:
                            {"children":forecastEnd},
                            label38764964:
                            {"children":DateTime.fromISO(jobs[j].createdAt).toLocaleString()},
                            Badge:
                            {"children":jobStatus, variation:jobColour},
                            label38764966:
                            {onClick: () => viewMetrics(jobs[j].id), children:viewButton},
                            label38764972:
                            {onClick: () => viewForecast(jobs[j].id,jobs[j].ingestJobId), children:viewButton},
                            MyIcon:
                            {onClick: () => deleteForecast(jobs[j].id)}
                        }
                    }
                    id = {jobs[j].id}
                    key = {jobs[j].id}
                    display="flex"
                    gap="0"
                    direction="row"
                    width="unset"
                    height="80"
                    justifyContent="flex-start"
                    alignItems="center"
                    grow="0"
                    shrink="0"
                    basis="0"
                    alignSelf="stretch"
                    position="relative"
                    border="1px SOLID rgba(239,240,240,1)"
                    padding="30px 15px 30px 15px"
                    backgroundColor="rgba(255,255,255,1)"
                    
                ></ForecastsDataRow>
                );
            }
        }
    }

    const metricStyle = {content : {width:335,height:500,top: '50%',left: '50%'}};
    const createStyle = {content : {width:960,height:700,top: '15%',left: '15%'}};
    const deleteStyle = {content : {width:405,height:200,top: '50%',left: '50%'}};
    const modalContainerStyle = {
        modalContent: {
          position: 'relative',
          padding: '20px',
          backgroundColor: '#fff',
          borderRadius: '8px',
          width: '100%',
          margin: 'auto',
        },
        closeButton: {
          position: 'absolute',
          top: '10px',
          right: '10px',
          background: 'transparent',
          border: 'none',
          fontSize: '30px',
          cursor: 'pointer',
        },
      };
    if(isLoading) return (<Flex></Flex>)
    return (
        <div className="Account" style={{ width: "100%"}}>
        
            <ForecastJobMetricsModal isOpen={metricsModalIsOpen}  onClose={() => closeMetrics()}  algorithm = {metrics.Algorithm}   
            MAPE={Number(metrics.MAPE).toFixed(2)} 
            MASE={Number(metrics.MASE).toFixed(2)} 
            RMSE={Number(metrics.RMSE).toFixed(2)} 
            WAPE={Number(metrics.WAPE).toFixed(2)} />
          
            <Modal isOpen={forecastModalIsOpen}>
                <div style={modalContainerStyle.modalContent}>
                <button style={modalContainerStyle.closeButton} onClick={closeForecast}>x</button>
                <ForecastView forecastJobId={forecastJobId} ingestJobId={ingestJobId} />
                </div>
            </Modal>

           
             
            <DeleteModal 
            isOpen={deleteForecastModalIsOpen} 
            onRequestClose={closeDeleteForecast} 
            onDelete={confirmDeleteForecast}/>
         
            
        
          
            <Modal  style={createStyle} isOpen={createForecastModalIsOpen}>
                <CreateForecast closeNewForecast={closeNewForecast} closeForecastRefresh={closeForecastRefresh}/>
            </Modal>
                <Flex overflow={'auto'}>
                    
                    <Flex position={'relative'} overflow={'hidden'} grow={1}>
                        
                    <Flex
      gap="32px"
      direction="column"
      width="100%"
      justifyContent="flex-start"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      padding="32px 32px 32px 32px"
      
    >
      <Flex
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        
      >
        <Text
          fontFamily="Inter"
          fontSize="32px"
          fontWeight="500"
          color="rgba(13,26,38,1)"
          lineHeight="40px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Forecasts"
          
        ></Text>
        {create}
        
      </Flex>
      <Flex
      gap="0"
      direction="column"
      width="100%"
      justifyContent="flex-start"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      border="1px SOLID rgba(174,179,183,1)"
      borderRadius="12px"
      padding="0px 0px 0px 0px"
      
    >
      <ForecastsDataRowTitle
        display="flex"
        gap="0"
        direction="row"
        width="unset"
        height="65px"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        border="1px SOLID rgba(174,179,183,1)"
        padding="0px 15px 0px 15px"
        backgroundColor="rgba(255,255,255,1)"
        
      ></ForecastsDataRowTitle>
        {rows}
      
        
      </Flex>
      {previous}{next}
    </Flex>
   
                    </Flex>
                    
                    
                    
                </Flex>
                <GeneralFooter/>
        </div>
        
    );
}

export default withAuthenticator(ForecastList);



