import './App.css';

import LogoWithText from './ui-components/LogoWithText';

import '@aws-amplify/ui-react/styles.css';
import { Amplify, Auth, Cache,API,Hub } from 'aws-amplify';
import React from "react";
import { useState,useEffect,useRef } from 'react';
import Account from './routes/account.tsx';
import SalesReportContainer from './routes/sales-report-container.tsx';
import ConversionReportContainer from './routes/conversion-report-container.tsx';
import CorporateAnalyticsReportContainer from './routes/corporate-analytics-report-container.tsx';
import TrafficReportContainer from './routes/traffic-report-container.tsx';
import ForecastList from './routes/forecasts.tsx';
import HomePage from './routes/homepage.tsx'
import LandingPage from './routes/landing-page.tsx';
import Register from './routes/register.tsx';
import Products from './routes/products.tsx';
import Authorize from './routes/authorize.tsx';
import LoginPage from './routes/login-page.tsx';
import SignupPage from './routes/signup-page.tsx';
import awsconfig from './aws-exports';
import {Authenticator,CheckboxField, useAuthenticator, withAuthenticator,Link, Flex} from '@aws-amplify/ui-react';
import {Routes, Route, useLocation } from 'react-router-dom';
import { getUser,getStripeCustomer } from './graphql/queries';
import { createUser } from './graphql/mutations';
import  { useNavigate } from 'react-router'
import Sidebar from './components/sidebar.tsx';
// import MarketingFooter from './ui-components/MarketingFooter.jsx';

Amplify.configure(awsconfig)


function App() {
  const [user, setUser] = useState(null);
  const[isSignedIn, setIsSignedIn ] = useState(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => setUser(user))
      .catch(() => setUser(null));
  
  }, []);
  
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    checkUser();
  },[]);// <-- Have to pass in [] here or is called on every render

  useEffect(() => {
    const userData = Cache.getItem('user');
    const currentPath = location.pathname;

    if (userData && userData.firstName && userData.lastName) {
      if (currentPath === '/' || currentPath === '/signin' || currentPath === '/signup') {
        navigate('/sales-report');
      }
    }
    //TODO: enhance this functionality
    // if (!userData || !userData.firstName || !userData.lastName) {
    //   if (currentPath !== '/signin' && currentPath !== '/signup' && currentPath !== '/') {
    //     navigate('/');
    //   }
    // }
  }, [navigate, location.pathname]);

  const services = {
    async handleSignUp(formData) {
      let { username, password, attributes } = formData;
      // custom username
      username = username.toLowerCase();
      attributes.email = attributes.email.toLowerCase();
      console.log("siging up ",formData)
      // return Auth.signUp({
      //   username,
      //   password,
      //   attributes,
      //   autoSignIn: {
      //     enabled: true,
      //   },
      // });
      await signInOrUp();
    },
  };

  async function signInOrUp(){
    try{
      let cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: false
      });
      const { attributes } = cognitoUser;
      console.log("attributes",attributes)
      const result = await API.graphql({ query: getUser , variables:{id: attributes.email}});
      console.log("result1",result)
      if(result['data'] && result['data'].getUser){
        // console.log('from db',result['data'])
        var response;
        
        response = await API.graphql({
            query: getStripeCustomer
            ,variables : {"email": attributes.email, "stripeCustomerId": result['data'].getUser["stripeId"]}
        })
        const stripeData = JSON.parse(response["data"]["getStripeCustomer"]);
        console.log('stripeData',stripeData);
        var currentPlan = null;
        var currentCustomer = null;
        //find the exact plan they are on currently
        if(stripeData['body'] && stripeData['body']['data']){
          for(var i = 0; i < stripeData['body']['data'].length;i++){
            currentCustomer = stripeData['body']['data'][i];
            console.log("currentCustomer",currentCustomer)
            console.log("attributes.stripeId",result['data'].getUser["stripeId"])
            if(currentCustomer && currentCustomer['customer'] == result['data'].getUser["stripeId"]){
              //found the current plan 
              currentPlan = stripeData['body']['data'][i]['plan'];
            }
          }
        }
        if(currentPlan == null){
          navigate("/products");
        }
        else {
          console.log("1.4")
          let product = currentPlan['product']
          result['data'].getUser['plan'] = product;
          // result['data'].getUser['stripeSubsId'] = currentCustomer["status"];
          console.log('user now is ',result['data'].getUser)
          // console.log('current plan status now is ',currentCustomer["status"])
          Cache.setItem('user', result['data'].getUser);
          
          var subscriber = currentPlan["active"];
          if(!subscriber){
            navigate("/products");
          }
        }
        
        console.log("Checking user cache" ,Cache.getItem('user'))
      
      }
      else {
        // no user in the database yet so add details from cognito
        console.log("1.5")
        let cognitoUser = await Auth.currentAuthenticatedUser({
          bypassCache: false
        });
        const { attributes } = cognitoUser;
        const credentials = await Auth.currentUserCredentials();
        const createUserPayload = {
          email:attributes.email,
          username:attributes.email,
          identityID:credentials.identityId,
          firstName:attributes.given_name,
          lastName:attributes.family_name,
          hasRfToken:false,
          id:attributes.email
        }
    
        await API.graphql({query: createUser,variables:{input:createUserPayload}});
        
        const result = await API.graphql({ query: getUser , variables:{id:attributes.email}});
        
        if(result['data'].getUser){
          console.log('data',result['data'])
          Cache.setItem('user', result['data'].getUser);
          navigate("/products");
        }
      }
    }catch(exception){
      
    }
  }

  async function signIn() {
    await signInOrUp();
  }

  async function checkSubscription() {

    // check the cache item with 5 mins expiry
    if(Cache.getItem('stripe') == null){
      
      try{
        let cognitoUser = await Auth.currentAuthenticatedUser({
          bypassCache: false
        });
        
        if(cognitoUser){
          const { attributes } = cognitoUser;
          const result = await API.graphql({ query: getUser , variables:{id:attributes.email}});
          
          if(result['data'] && result['data'].getUser){
            
            var response;
            
            response = await API.graphql({
                query: getStripeCustomer
                ,variables : {"email": attributes.email, "stripeCustomerId": attributes.stripeId}
            })

            const stripeData = JSON.parse(response["data"]["getStripeCustomer"]);

            var currentPlan = null;
            var subscriber = null;
            //find the exact plan they are on currently
            if(stripeData['body'] && stripeData['body']['data']){
              for(var i = 0; i < stripeData['body']['data'].length;i++){
                let currentCustomer = stripeData['body']['data'][i];
                if(currentCustomer['customer'] == result['data'].getUser["stripeId"]){
                  //found the current plan 
                  // currentPlan = stripeData['body']['data'][i]['plan'];
                  subscriber =  stripeData["body"]["data"][i];
                  
                  var current = new Date(); 
                  var fiveMinutes = new Date(current.getTime() + 300000);
                  console.log("five mins ",fiveMinutes)
                  Cache.setItem('stripe', subscriber, { expires: fiveMinutes.getTime() });
                  console.log("subscriber is ",subscriber,Cache.getItem('stripe'));
                  
                }
              }
            }
            // if(!subscriber){
            //   navigate("/accounts");
            // }
            // if(stripeData['body']['data'] != null && stripeData['body']['data'][0] !=null){
            //   console.log('stripeData',stripeData);
            //   let plan = stripeData['body']['data'][0]['plan']['product']
            //   result['data'].getUser['plan'] = plan;
              
              
            // }
          }
        }
        
      }
      catch (error) {
        console.error(error);

      }
    }
      
  }
  
  async function checkUser(){
    try{
      console.log("Checking user cache" ,Cache.getItem('user'))
      Hub.listen('auth', ({ payload }) => {
        const { event } = payload;
        if(event == 'signOut'){
          Cache.setItem('user',null);
          Cache.setItem('stripe', null);
          setIsSignedIn(false);
        }
        if(event == 'signIn'){
          signIn();
          setIsSignedIn(true);
        }
        console.log('Auth ',event)
        
      });
    }catch(exception){
      
    }
  }
  
  checkSubscription();
  
  return (
    <Routes>
      <Route element={<Sidebar />}>
        <Route path="*" element={<SalesReportContainer />} />
        <Route path="/forecasts" element={<ForecastList />} />
        <Route path="/sales-report" element={<SalesReportContainer />} />
        <Route path="/traffic-report" element={<TrafficReportContainer />} />
        <Route path="/conversion-report" element={<ConversionReportContainer />} />
        <Route path="/corporate-analytics-report" element={<CorporateAnalyticsReportContainer />} />
        <Route path="/account" element={<Account />} />
        <Route path="/register" element={<Register />} />
        <Route path="/products" element={<Products />} />
        <Route path="/authorize" element={<Authorize />} />

       
      </Route>
      <Route path="/"  element={<LandingPage/>} />
      <Route path="/signin"  element={<LoginPage setUser={setUser}/>} />
      <Route path="/signup" element={<SignupPage />} />
    </Routes>
  )
      
}

export default App

