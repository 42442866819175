/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Text, Image, Button, Divider } from "@aws-amplify/ui-react";

const CustomFooter = (props) => {
    const {  overrides, ...rest } = props;


  const newsletter = {
    container: {
      backgroundColor: "#F9F9F9",
      // borderTop: '1px solid #ccc',
      // display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      marginTop: '35px',
      paddingBottom:'30px',
      fontFamily: 'Arial, sans-serif',
      fontSize: '14px',
      color: '#333',
    },
    form: {
      display: 'block',
      alignItems: 'center',
      paddingTop: "40px"
    },
    input: {
      padding: '10px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      marginRight: '10px',
      fontSize: '1.1em',
      width: "300px",
      outline: 'none',
    },
    button: {
      padding: '10px 20px',
      border: '1px solid rgb(204, 204, 204)',
      backgroundColor: '#FFF',
      color: '#000',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '1.1em',
      fontWeight: 'bold'
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      marginTop: '40px',
      paddingLeft:'50px',
      paddingRight: '50px',
      paddingBottom: '20px',
      paddingTop: '10px',
    },
    logo: {
    },
    logoImage: {
      height: '40px',
      marginRight: '5px',
    },
    copyright: {
      fontSize: '1.1em',
      color: '#777',
    }
  };
  return (
    <Flex
      style={newsletter.container}
      {...getOverrideProps(overrides, "NewsletterContainer")}
    >
      <Divider
        width="94.5%"
        marginTop="20px"
        {...getOverrideProps(overrides, "Divider")}
      />
      <Flex
        alignItems="center"
        justifyContent="space-between"
        paddingLeft="50px"
        paddingRight="50px"
        width="100%"
        {...getOverrideProps(overrides, "NewsletterHeader")}
      >
        <Flex  display="block" >
          <Text
            color="#304050"
            fontSize="1.3em"
            fontWeight="bold"
            paddingTop="20px"
            {...getOverrideProps(overrides, "NewsletterTitle")}
          >
            Sign up for our newsletter
          </Text>
          <Text
            paddingTop="5px"
            fontSize="1.2em"
            {...getOverrideProps(overrides, "NewsletterSubtitle")}
          >
            No spam. We promise.
          </Text>
        </Flex>
        <form style={newsletter.form} {...getOverrideProps(overrides, "NewsletterForm")}>
          <input
            type="email"
            placeholder="Your email"
            style={newsletter.input}
            {...getOverrideProps(overrides, "NewsletterInput")}
          />
          <Button
            type="submit"
            style={newsletter.button}
            {...getOverrideProps(overrides, "NewsletterButton")}
          >
            Subscribe
          </Button>
        </form>
      </Flex>
      <Flex
        style={newsletter.footer}
        {...getOverrideProps(overrides, "NewsletterFooter")}
      >
        <Flex style={newsletter.logo} {...getOverrideProps(overrides, "NewsletterLogo")}>
          <Image
            src="gaiff_logo.png"
            alt="Global AI Internet Freedom Fund"
            style={newsletter.logoImage}
            {...getOverrideProps(overrides, "NewsletterLogoImage")}
          />
        </Flex>
        <Text
            style={newsletter.copyright}
          {...getOverrideProps(overrides, "NewsletterCopyright")}
        >
          © 2021-2024 Aitium®. All rights reserved.
        </Text>
      </Flex>
    </Flex>
  );
};

export default CustomFooter;