/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      email
      username
      identityID
      firstName
      lastName
      hasRfToken
      stripeId
      plan
      stripeSubsId
      paymentSource
      lastPaymentDate
      nextPaymentDate
      trialEndDate
      stripeActiveCheck
      STRDataStartDate
      STRDataEndDate
      Marketplace
      createdAt
      updatedAt
      owner
      refreshToken
      sellingPartnerIds
      sellerIdNA
      id
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      email
      username
      identityID
      firstName
      lastName
      hasRfToken
      stripeId
      plan
      stripeSubsId
      paymentSource
      lastPaymentDate
      nextPaymentDate
      trialEndDate
      stripeActiveCheck
      STRDataStartDate
      STRDataEndDate
      Marketplace
      createdAt
      updatedAt
      owner
      refreshToken
      sellingPartnerIds
      sellerIdNA
      id
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      email
      username
      identityID
      firstName
      lastName
      hasRfToken
      stripeId
      plan
      stripeSubsId
      paymentSource
      lastPaymentDate
      nextPaymentDate
      trialEndDate
      stripeActiveCheck
      STRDataStartDate
      STRDataEndDate
      Marketplace
      createdAt
      updatedAt
      owner
      refreshToken
      sellingPartnerIds
      sellerIdNA
      id
      __typename
    }
  }
`;
export const createJob = /* GraphQL */ `
  mutation CreateJob(
    $input: CreateJobInput!
    $condition: ModelJobConditionInput
  ) {
    createJob(input: $input, condition: $condition) {
      id
      userID
      createdAt
      user {
        email
        username
        identityID
        firstName
        lastName
        hasRfToken
        stripeId
        plan
        stripeSubsId
        paymentSource
        lastPaymentDate
        nextPaymentDate
        trialEndDate
        stripeActiveCheck
        STRDataStartDate
        STRDataEndDate
        Marketplace
        createdAt
        updatedAt
        owner
        refreshToken
        sellingPartnerIds
        sellerIdNA
        id
        __typename
      }
      ingestJobID
      ingestJob {
        id
        marketID
        createdAt
        startIngestDateTime
        endIngestDateTime
        lastIngestedOrderDateTime
        ingestIntervalDays
        status
        sellerId
        cognitoID
        updatedAt
        ingestJobJobId
        owner
        __typename
      }
      ingestJobStatus
      forecastJobStatus
      forecastJobID
      forecastJob {
        id
        createdAt
        status
        isDeleted
        startIngest
        endIngest
        ingestJobId
        market
        sellingPartnerId
        updatedAt
        forecastJobJobId
        owner
        __typename
      }
      dashboardId
      bucket
      userIDCognito
      updatedAt
      jobUserId
      jobIngestJobId
      jobForecastJobId
      owner
      __typename
    }
  }
`;
export const updateJob = /* GraphQL */ `
  mutation UpdateJob(
    $input: UpdateJobInput!
    $condition: ModelJobConditionInput
  ) {
    updateJob(input: $input, condition: $condition) {
      id
      userID
      createdAt
      user {
        email
        username
        identityID
        firstName
        lastName
        hasRfToken
        stripeId
        plan
        stripeSubsId
        paymentSource
        lastPaymentDate
        nextPaymentDate
        trialEndDate
        stripeActiveCheck
        STRDataStartDate
        STRDataEndDate
        Marketplace
        createdAt
        updatedAt
        owner
        refreshToken
        sellingPartnerIds
        sellerIdNA
        id
        __typename
      }
      ingestJobID
      ingestJob {
        id
        marketID
        createdAt
        startIngestDateTime
        endIngestDateTime
        lastIngestedOrderDateTime
        ingestIntervalDays
        status
        sellerId
        cognitoID
        updatedAt
        ingestJobJobId
        owner
        __typename
      }
      ingestJobStatus
      forecastJobStatus
      forecastJobID
      forecastJob {
        id
        createdAt
        status
        isDeleted
        startIngest
        endIngest
        ingestJobId
        market
        sellingPartnerId
        updatedAt
        forecastJobJobId
        owner
        __typename
      }
      dashboardId
      bucket
      userIDCognito
      updatedAt
      jobUserId
      jobIngestJobId
      jobForecastJobId
      owner
      __typename
    }
  }
`;
export const deleteJob = /* GraphQL */ `
  mutation DeleteJob(
    $input: DeleteJobInput!
    $condition: ModelJobConditionInput
  ) {
    deleteJob(input: $input, condition: $condition) {
      id
      userID
      createdAt
      user {
        email
        username
        identityID
        firstName
        lastName
        hasRfToken
        stripeId
        plan
        stripeSubsId
        paymentSource
        lastPaymentDate
        nextPaymentDate
        trialEndDate
        stripeActiveCheck
        STRDataStartDate
        STRDataEndDate
        Marketplace
        createdAt
        updatedAt
        owner
        refreshToken
        sellingPartnerIds
        sellerIdNA
        id
        __typename
      }
      ingestJobID
      ingestJob {
        id
        marketID
        createdAt
        startIngestDateTime
        endIngestDateTime
        lastIngestedOrderDateTime
        ingestIntervalDays
        status
        sellerId
        cognitoID
        updatedAt
        ingestJobJobId
        owner
        __typename
      }
      ingestJobStatus
      forecastJobStatus
      forecastJobID
      forecastJob {
        id
        createdAt
        status
        isDeleted
        startIngest
        endIngest
        ingestJobId
        market
        sellingPartnerId
        updatedAt
        forecastJobJobId
        owner
        __typename
      }
      dashboardId
      bucket
      userIDCognito
      updatedAt
      jobUserId
      jobIngestJobId
      jobForecastJobId
      owner
      __typename
    }
  }
`;
export const createIngestJob = /* GraphQL */ `
  mutation CreateIngestJob(
    $input: CreateIngestJobInput!
    $condition: ModelIngestJobConditionInput
  ) {
    createIngestJob(input: $input, condition: $condition) {
      id
      marketID
      createdAt
      startIngestDateTime
      endIngestDateTime
      lastIngestedOrderDateTime
      ingestIntervalDays
      resultFile {
        bucket
        region
        key
        __typename
      }
      status
      sellerId
      cognitoID
      job {
        id
        userID
        createdAt
        ingestJobID
        ingestJobStatus
        forecastJobStatus
        forecastJobID
        dashboardId
        bucket
        userIDCognito
        updatedAt
        jobUserId
        jobIngestJobId
        jobForecastJobId
        owner
        __typename
      }
      updatedAt
      ingestJobJobId
      owner
      __typename
    }
  }
`;
export const updateIngestJob = /* GraphQL */ `
  mutation UpdateIngestJob(
    $input: UpdateIngestJobInput!
    $condition: ModelIngestJobConditionInput
  ) {
    updateIngestJob(input: $input, condition: $condition) {
      id
      marketID
      createdAt
      startIngestDateTime
      endIngestDateTime
      lastIngestedOrderDateTime
      ingestIntervalDays
      resultFile {
        bucket
        region
        key
        __typename
      }
      status
      sellerId
      cognitoID
      job {
        id
        userID
        createdAt
        ingestJobID
        ingestJobStatus
        forecastJobStatus
        forecastJobID
        dashboardId
        bucket
        userIDCognito
        updatedAt
        jobUserId
        jobIngestJobId
        jobForecastJobId
        owner
        __typename
      }
      updatedAt
      ingestJobJobId
      owner
      __typename
    }
  }
`;
export const deleteIngestJob = /* GraphQL */ `
  mutation DeleteIngestJob(
    $input: DeleteIngestJobInput!
    $condition: ModelIngestJobConditionInput
  ) {
    deleteIngestJob(input: $input, condition: $condition) {
      id
      marketID
      createdAt
      startIngestDateTime
      endIngestDateTime
      lastIngestedOrderDateTime
      ingestIntervalDays
      resultFile {
        bucket
        region
        key
        __typename
      }
      status
      sellerId
      cognitoID
      job {
        id
        userID
        createdAt
        ingestJobID
        ingestJobStatus
        forecastJobStatus
        forecastJobID
        dashboardId
        bucket
        userIDCognito
        updatedAt
        jobUserId
        jobIngestJobId
        jobForecastJobId
        owner
        __typename
      }
      updatedAt
      ingestJobJobId
      owner
      __typename
    }
  }
`;
export const createForecastJob = /* GraphQL */ `
  mutation CreateForecastJob(
    $input: CreateForecastJobInput!
    $condition: ModelForecastJobConditionInput
  ) {
    createForecastJob(input: $input, condition: $condition) {
      id
      forecastParam {
        JobRoot
        Domain
        TimeSeriesDataFrequency
        TimeSeriesTimestampFormat
        ForecastHorizon
        PerformAutoML
        DeleteOnCompletion
        PredAlgo
        holiday
        __typename
      }
      resultFile {
        bucket
        region
        key
        __typename
      }
      createdAt
      status
      isDeleted
      startIngest
      endIngest
      ingestJobId
      market
      sellingPartnerId
      job {
        id
        userID
        createdAt
        ingestJobID
        ingestJobStatus
        forecastJobStatus
        forecastJobID
        dashboardId
        bucket
        userIDCognito
        updatedAt
        jobUserId
        jobIngestJobId
        jobForecastJobId
        owner
        __typename
      }
      updatedAt
      forecastJobJobId
      owner
      __typename
    }
  }
`;
export const updateForecastJob = /* GraphQL */ `
  mutation UpdateForecastJob(
    $input: UpdateForecastJobInput!
    $condition: ModelForecastJobConditionInput
  ) {
    updateForecastJob(input: $input, condition: $condition) {
      id
      forecastParam {
        JobRoot
        Domain
        TimeSeriesDataFrequency
        TimeSeriesTimestampFormat
        ForecastHorizon
        PerformAutoML
        DeleteOnCompletion
        PredAlgo
        holiday
        __typename
      }
      resultFile {
        bucket
        region
        key
        __typename
      }
      createdAt
      status
      isDeleted
      startIngest
      endIngest
      ingestJobId
      market
      sellingPartnerId
      job {
        id
        userID
        createdAt
        ingestJobID
        ingestJobStatus
        forecastJobStatus
        forecastJobID
        dashboardId
        bucket
        userIDCognito
        updatedAt
        jobUserId
        jobIngestJobId
        jobForecastJobId
        owner
        __typename
      }
      updatedAt
      forecastJobJobId
      owner
      __typename
    }
  }
`;
export const deleteForecastJob = /* GraphQL */ `
  mutation DeleteForecastJob(
    $input: DeleteForecastJobInput!
    $condition: ModelForecastJobConditionInput
  ) {
    deleteForecastJob(input: $input, condition: $condition) {
      id
      forecastParam {
        JobRoot
        Domain
        TimeSeriesDataFrequency
        TimeSeriesTimestampFormat
        ForecastHorizon
        PerformAutoML
        DeleteOnCompletion
        PredAlgo
        holiday
        __typename
      }
      resultFile {
        bucket
        region
        key
        __typename
      }
      createdAt
      status
      isDeleted
      startIngest
      endIngest
      ingestJobId
      market
      sellingPartnerId
      job {
        id
        userID
        createdAt
        ingestJobID
        ingestJobStatus
        forecastJobStatus
        forecastJobID
        dashboardId
        bucket
        userIDCognito
        updatedAt
        jobUserId
        jobIngestJobId
        jobForecastJobId
        owner
        __typename
      }
      updatedAt
      forecastJobJobId
      owner
      __typename
    }
  }
`;
